<template>
   

       <DataTable :value="data"  responsiveLayout="scroll" stripedRows  :paginator="true" :rows="10"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        :rowsPerPageOptions="[10,20,50]"  v-model:filters="filters" :globalFilterFields="filedsArrayForFilter"
                        currentPageReportTemplate="ნაჩვენებია {first} - {last} , {totalRecords} -დან"   ref="dt"  >

                       
                            <div class="row mb-3">
                                <div class="col-2">
                                    <button class="btn btn-success" @click="exportCSV($event)">ექსელი</button>

                                </div>
                                <div class="col-10">
                                     <div class="input-group ">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"><i class='bx bx-search'></i></span>
                                            </div> 
                                              <input v-model="filters['global'].value" placeholder="ძებნა..."  class="form-control"/>
                                     </div>      
                                </div>
                                 
                                
                            </div>
               



                   
                    <Column v-for="(column,i) in tableFields" :field="column.col" :header="column.label" :sortable="true" :key="i"></Column>
                    
                </DataTable>


</template>


<script>
 import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import {FilterMatchMode,FilterOperator} from 'primevue/api';

export default{

      data(){

            return{

                     filters: null,
                     
                    


            }


      },

      props:['data','tableFields','filedsArrayForFilter']

      ,
       created(){

           this.initFilters()
       },
       components:{

            DataTable,Column

       },
       methods:{

              initFilters() {
                     this.filters = {
                        'global': {value: null, matchMode: FilterMatchMode.CONTAINS} 
                     }
            },
               exportCSV() {
                     this.$refs.dt.exportCSV();
               } 
       } 




}



</script>
