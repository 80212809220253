<template>


  <table class="table">
    <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">სახელი</th>
      <th scope="col">გვარი</th>
      <th scope="col">მოთხოვნა</th>
      <th scope="col">ფასი</th>
      <th scope="col">თანხმობა</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(logistics,i) in logistic " :key="i">
      <th scope="row">{{logistics.LogisticsRequestID}}</th>
      <td>{{logistics.saxeli}}</td>
      <td>{{logistics.gvari}}</td>
      <td style="text-wrap:none">{{logistics.Request}}</td>
      <td> <div>{{logistics.Price}}</div> </td>
      <td><div class="form-check">
        <input type="checkbox" class="form-check-input" :id="'approval'+logistics.LogisticsRequestID+''"    :checked="logistics.Approve==1?true:false"  @click="updateApprve(logistics.LogisticsRequestID,logistics.Approve==0?1:0)" >
        <label class="form-check-label">დადასტურება</label>
      </div>
      </td>


    </tr>

    </tbody>
  </table>

</template>

<script>
export default {
  data(){
    return{
logistic:null,
      forUpdateArray:[]


    }

  },mounted() {


    this.$store.dispatch('selectlogisticss').then(response=>{

    this.logistic=response.data






    })



  },methods:{

    updateApprve(LogisticsRequestID,isChecked){





      this.$store.dispatch('updateApprvee',{forUpdate:LogisticsRequestID,isChecked:isChecked}).then(response=>{
        if(response.data.status=='OK'){
          this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით  განახლდა')

        }else {
          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!')

        }

      })

    }
  }

}

</script>