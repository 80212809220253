<template>

  <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col">სახელი</th>
      <th scope="col">გვარი</th>
      <th scope="col">კლასი</th>
      <th scope="col">კოდი</th>
      <th scope="col">თანხა</th>
      <th scope="col">კონტრაქტის გაფორმების თარიღი</th>
      <th scope="col">კონტრაქტის დასრულება</th>
    </tr>n
    </thead>
    <tbody>
    <tr v-for="(getcon,i) in contract " :key="i">
      <th >{{getcon.saxeli}}</th>
      <td>{{getcon.gvari}}</td>
      <td>{{getcon.classss}}</td>
      <td>{{getcon.kodi}}</td>
      <td>{{getcon.fee}}</td>
      <td>{{getcon.contract_start_date}}</td>
      <td>{{getcon.contract_end_date}}</td>
    </tr>
    </tbody>
  </table>

</template>
<script>
export default {
data(){
  return{
    contract:null,


  }


},
  mounted() {
  this.$store.dispatch('expiredcontraqts').then(response=>{

    this.contract=response.data
  })

  }


}
</script>

