<template>
<div class="container my-5">
  <div class="row">
    <div class="col text-center">
      <label>კლუბი</label>
      <input class="form-control" type="text" v-model="form.club">
    </div>
    <div class="col text-center">
      <label>მოსწავლე</label>
      <Multiselect
          v-model="form.moswavleID"
          :options="moswavle"
          :close-on-select="false"
          mode="tags"
          :searchable="true"/>
    </div>
    <div class="col text-center">
      <label>მასწავლებელი</label>
      <Multiselect
          v-model="form.teacherID"
          :options="teacher"
          :close-on-select="false"
          :searchable="true"/>
    </div>
    <div class="col text-center">
      <label>დაწყების დრო</label>
      <input class="form-control" type="time" v-model="form.star_time">
    </div>
    <div class="col text-center">
      <label>დას.დრო</label>
      <input class="form-control" type="time" v-model="form.end_time">
    </div>
    <div class="col text-center">
      <label>დღე</label>
      <Multiselect
          v-model="form.dgeID"
          :options="dge"
          :close-on-select="false"
          mode="tags"
          :searchable="true"/>

    </div>
    <div class="col text-center">
      <label>მობილური</label>
      <input class="form-control" type="number" v-model="form.mobile">
    </div>
    <div class="col"> <button type="button" class="btn btn-secondary btn-block " style="margin-top: 31px" @click="saveclub()">შენახვა</button></div>
  </div>
  <div class="row my-5">
    <div class="col">
      <p class="font-weight-bold">კლუბი</p>
      <div class="mt-3"  style="cursor: pointer"  @click="nextclubinfo(club.clubID)" v-for="(club,i) in clubb " :key="i"> {{club.club_name}}</div>
    </div>
    <div class="col">
<div class="row"  v-for="(clubinfo,i) in nextclubinfoo " :key="i">
  <div class="col-2">{{clubinfo.dge}}</div>
  <div class="col-4">{{clubinfo.start_time}} - {{clubinfo.end_time}}</div>
  <div class="col-4">{{clubinfo.persaxeli}} {{clubinfo.persgvari}}</div>
  <div class="col-2">{{clubinfo.mobiluri}}</div>

</div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <p class="text-center font-weight-bold">ბავშვების სია</p>
      <div class="text-center" v-for="(list,i) in listofstudent " :key="i">{{list.saxeli}} {{list.gvari}} </div>
    </div>
  </div>
</div>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import Editor from "@tinymce/tinymce-vue";

export default {
  components: { Multiselect,editor:Editor },
  data(){
    return{
      formData: new FormData(),
      form:{
        moswavleID:null,
        teacherID:null,
        dgeID:null,
        star_time:null,
        end_time:null,
        mobile:null,
        club:null

      },
      moswavle:null,
      teacher:null,
      dge:null,
      clubb:null,
      nextclubinfoo:null,
      listofstudent:null
    }
  },mounted() {
    this.$store.dispatch('selectmoswavleclub').then(response=>{

      this.moswavle=response.data


    })
    this.$store.dispatch('selectclub').then(response=>{
console.log(response.data)
      this.clubb=response.data


    })
    this.$store.dispatch('selectteacherclub').then(response=>{

      this.teacher=response.data


    })
    this.$store.dispatch('selectdgeclub').then(response=>{

      this.dge=response.data


    })
  },methods:{

    saveclub(){

      this.formData.append('moswavleID', this.form.moswavleID)
      this.formData.append('teacherID', this.form.teacherID)
      this.formData.append('dgeID', this.form.dgeID)
      this.formData.append('star_time', this.form.star_time)
      this.formData.append('end_time', this.form.end_time)
      this.formData.append('mobile', this.form.mobile)
      this.formData.append('club', this.form.club)
      this.$store.dispatch('saveclub', this.formData).then(response => {
        if (response.data.status == 'OK') {
          this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით  შეინახა')

        } else {
          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!')

        }

      })
    },
    nextclubinfo(clubID){
      this.$store.dispatch('nextclubinfooo',{clubID:clubID}).then(response=>{
console.log(response.data)
        this.nextclubinfoo=response.data


      })
      this.$store.dispatch('selectlisofstudents',{clubID:clubID}).then(response=>{
        console.log(response.data)
        this.listofstudent=response.data


      })
    }
  }
}

</script>