<template>
  <div class="modal fade" :id="id" data-bs-backdrop="true"  tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" v-bind:class="'modal-' + size">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">{{title}}</h5>
          <button type="button" class="btn" data-bs-dismiss="modal" aria-label="Close">X</button>
        </div>
        <div class="modal-body">
          <slot name="body">
              
           


          </slot>
        </div>
        
      </div>
    </div>
  </div>
</template>
<script>
import {Modal} from 'bootstrap'  
export default {
   
  props: {
    isOpen: {},
    id: {},
    title: {},
    
    size: {
      default: 'xl'
    } 
  },

  watch: {
    isOpen (val) {
      if (val) this.open()
      else this.modal.hide()
    }
  },

  data () {
    return {
      modal: null
    }
  },

  mounted () {
    const modalEl = document.getElementById(this.id)
    this.modal = new Modal(modalEl)
    modalEl.addEventListener('hidden.bs.modal', () => {
      this.$emit('close')
    })
  },

  methods: {
    close () {
      this.modal.hide()
      this.$emit('close')
    },

    open () {
      this.modal.show()
      this.$emit('show')
    }
  }
}
</script>