<template>

  <div class="container-fluid">
      <div class="row">
        <div class=" col-sm-12 col-md-4  ">
          <img src="../assets/image/staff/3.jpg" class="img  images  mx-auto d-block rounded-circle" alt="">
          <h4 class=" mt-3 font-weight-bold text-center">{{ this.$store.state.personditeil.saxeli }} {{ this.$store.state.personditeil.gvari }} </h4>


        </div>

        <div class=" col-sm-12 col-md-8 mt-5">


          <p>
         <span class="font-weight-bold"> პირადობის სერია:</span>   {{ this.$store.state.personditeil.piradoba_seria }}
          </p>
        <p>
          <span class="font-weight-bold"> პირადობის N:</span>   {{ this.$store.state.personditeil.piradoba }}
        </p>
      <p>
          <span class="font-weight-bold"> მობილური:</span>   {{ this.$store.state.personditeil.mobiluri }}
      </p>
    <p>
          <span class="font-weight-bold"> მისამართი:</span>   {{this.$store.state. personditeil.misamarTi }}
    </p>

        </div>



      </div>




    <div class="row">
      <div class="col-12 ">

        <table class="table table-striped  table-responsive-md  mt-2">
          <thead>
          <tr>
            <th scope="col">კონტრაქტის ნომერი</th>
            <th scope="col">კონტრაქტის დაწყება</th>
            <th scope="col">კონტრაქტის შეწყვეტა</th>
            <th scope="col">შეწყვეტის თარიღი</th>
            <th scope="col">ხელფასი</th>
            <th scope="col">კონტრაქტის ტიპი</th>
            <th scope="col">სტატუსის ტიპი</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(personinfo,i) in this.$store.state.personditeil.contracts" :key="i">
            <th scope="row">{{personinfo.contract_number}}</th>
            <td> {{personinfo.contract_start_date}}</td>
            <td> {{personinfo.contract_end_date}}</td>
            <td> {{personinfo.shewyvetis_tar}}</td>
            <td> {{personinfo.salary}}</td>
            <td> {{personinfo.contract_type}}</td>
            <td> {{personinfo.status_type}}</td>

          </tr>


          </tbody>
        </table>

      </div>

    </div>




  </div>



</template>

<style scoped>

.images{
  width: 250px;
  height: 250px;
  margin-top: 15px;

}
</style>

