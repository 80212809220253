<template>
<div class="container">
  <div style="font-size:50px; font-weight:bold; position: relative; left: 100%; cursor:pointer;" @click="addfield()" >+</div>
<div class="row" v-for="(select,$i) in selecttheme " :id="'row'+$i">

  <div class="col-6">
    <div class="form-group">
      <input type="text" class="form-control" id="text" v-model="selecttheme[$i].text">
    </div>
  </div>

  <div class="col-1">
    <span class="position-relative " @click="deletefield($i)" style="cursor: pointer">-</span>
  </div>
</div>

  <button class="btn btn-secondary p-2 mt-4" style="position: relative;left: 44%;" @click="logisticsRequest()">შენახვა</button>


</div>





</template>

<script>
export default {

  data(){


    return{
      selecttheme:[{
        text: '',
      }],



    }


  },methods:{
    addfield() {
      this.selecttheme.push({
        text: '',
      });


    },
    deletefield(index){

      this.selecttheme.splice(index,1)

    },

    logisticsRequest(){

      this.$store.dispatch('logisticsRequest',{selecttheme:this.selecttheme,personID:this.$store.state.user.personaliID}).then(response=>{

        // console.log(response.data)

        if (response.data.status == 'OK') {
          this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით შეინახა')
        } else if (response.data.status == 'ERROR') {
          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა')
        }


      })

    }

  }


}
</script>