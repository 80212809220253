<template>

<div class="container">
    <div class="row mt-2">
        <div class="col-md-6 mt-2 col-lg-4">
      <div class="card" v-for="(subject,i) in subjectinfo" :key="i">
        <div class="card-body  text-center">
        <router-link :to="'/AssessmentBysubject/'+subject.klasiID+'/'+subject.paramID+'/'+subject.klasi+'/'+subject.semestriID" class="router text-decoration-none">{{subject.sagani}} {{subject.klasi}}</router-link>
        </div>
      </div>

          </div>

</div>

</div>

</template>

<script>
export default {
  data(){
    return{

      subjectinfo:null,

    }


  },mounted() {
    this.$store.dispatch('getsubjectinfo',{personID:this.$store.state.user.personaliID}).then(response=>{
      this.subjectinfo=response.data

    })

  }

}


</script>
<style>

.router{
  font-size: 20px;

}

</style>
