<template>

    <div class="container">



          <div class="row" v-for="(day,i) in days" :key="i">


                <div class="col-12 mt-2">
                      <h3 class="text-center">{{ day }}</h3>
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th scope="col"> გაკვეთილის #</th>
                      <th scope="col">საგანი</th>
                      <th scope="col">აუდიტორია</th>
                      <th scope="col">კლასები</th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr  v-for="(perstable,index) in persontable[i]" :key="index">


                      <th scope="row">{{perstable.gakvetilisN}}</th>
                      <td>{{perstable.sagani}}</td>
                      <td>{{perstable.auditoria}}</td>
                      <td>{{perstable.klasi}}</td>

                    </tr>

                    </tbody>
                  </table>

                </div>



          </div>






</div>








</template>
<script>
export default {
  data(){
    return{
      days: {1:'ორშაბათი',2:'სამშაბათი',3:'ოთხშაბათი',4:'ხუთშაბათი',5:'პარასკევი'},
      persontable:0,

    }

  },mounted() {

    this.$store.dispatch('persontable',{personID:this.$store.state.user.personaliID}).then(response=>{

     this.persontable=response.data



    })
  }


}





</script>