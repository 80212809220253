<template>

  <div class="container" >
        <div class="row text-right">
          <div class="col-12 mt-4">
            <button type="button" class="btn btn-secondary" @click="Createticket()">ბილეთის შექმნა</button>
          </div>
          <div class="col-12  mt-2">
            <button type="button" class="btn btn-secondary" @click="watchpoint()">ბილეთის ნახვა</button>
          </div>

        </div>
    <div class="row">

        <div class="col-3">

        </div>
        <div class="col-9">
          <form class="form-inline">
            <lable class="mr-2">თემა</lable>
            <input class="form-control w-50 p-4 rounded-pill" type="text" v-model="theme">
          <button class="btn btn-secondary ml-4 p-3 rounded-pill" @click="addtheme()" type="button">დამატება</button>
          </form>

        </div>

      </div>

          <div class="row">

                <div class="col-12 mt-3">

                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th scope="col">თემა</th>
                      <th scope="col">საგანი</th>
                      <th scope="col">კლასი</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(them,i) in themeinfo " :key="i" @click="qula(them.themeID)" style="cursor: pointer">
                      <th scope="row">{{them.theme}}</th>
                      <th scope="row">{{them.sagani}}</th>
                      <th scope="row">{{them.classID}} კლასი</th>
                    </tr>


                    </tbody>
                  </table>


                </div>


          </div>


  </div>




</template>

<script>
export default {
  data(){

    return{
      theme:null,
      themeinfo:null,

    }
  },methods:{
    addtheme(){
      this.$store.dispatch('addtheme',{klasiID:this.$route.params.klasiID,saganiID:this.$route.params.saganiID,theme:this.theme,personID:this.$store.state.user.personaliID}).then(response=>{

        if (response.data.status == 'OK') {

          this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით დაემატა')

        } else {
          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!')

        }


      })

    },
    watchpoint(){
      this.$router.push('/watchpoint/'+this.$route.params.klasiID+'/'+this.$route.params.saganiID)
    },


    qula(them){

      this.$router.push('/Score/'+them)
    },
    Createticket(){

      this.$router.push('/Createticket/'+this.$route.params.klasiID+'/'+this.$route.params.saganiID)

    }

  },mounted() {
    this.$store.dispatch('gettheme',{personID:this.$store.state.user.personaliID,klasiID:this.$route.params.klasiID,saganiID:this.$route.params.saganiID}).then(response=>{

      this.themeinfo=response.data




    })



  }

}

</script>