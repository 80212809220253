<template>

  <div class="container mt-3">
    <form action="">
          <div class="row">
              <div class="  col-lg-3  col-md-6 col-sm-12 ">
                <div class="form-group">
                  <input type="text" class="form-control" v-model="form.fname"  placeholder="სახელი" id="fname">


                </div>
              </div>
            <div class=" col-lg-3  col-md-6 col-sm-12">
              <div class="form-group">
                <input type="text" class="form-control" v-model="form.lname" placeholder="გვარი" id="lname">


              </div>
            </div>
            <div class=" col-lg-3  col-md-6 col-sm-12">


                <Multiselect
                    id="value"
                    placeholder="აირჩიეთ კლასი"
                    v-model="form.value"
                    :options="classss"
                    :close-on-select="true"
                    :searchable="true"/>



            </div>

            <div class="  col-lg-3  col-md-6 col-sm-12">
              <div class="form-group">
                <input type="text" class="form-control" v-model="form.piradobisN" placeholder="პირადობის N" id="piradobisN">


              </div>
            </div>

            <div class="  col-lg-3  col-md-6 col-sm-12">
              <div class="form-group">
                <input type="date" class="form-control" v-model="form.dabadebistarixi" placeholder="დაბადების თარიღი" id="dabadebistarixi">


              </div>
            </div>
            <div class=" col-lg-3  col-md-6 col-sm-12">
              <div class="form-group">
                <input type="text" class="form-control" v-model="form.dabadebisadgili" placeholder="მოქალაქეობა" id="dabadebisadgili">


              </div>
            </div>
            <div class="  col-lg-3  col-md-6 col-sm-12">
              <div class="form-group">
                <input type="text" class="form-control" v-model="form.addres" placeholder="მისამართი" id="addres">


              </div>
            </div>
            <div class=" col-lg-3  col-md-6 col-sm-12">
              <div class="form-group">
                <input type="number" class="form-control" v-model="form.phone" placeholder="ტელეფონი" id="phone">


              </div>
            </div>
            <div class=" col-lg-3  col-md-6 col-sm-12">
              <div class="form-group">
                <input type="number" class="form-control" v-model="form.number" placeholder="მობილური" id="number">


              </div>
            </div>
            <div class="  col-lg-3  col-md-6 col-sm-12">
              <div class="form-group">
                <input type="email" class="form-control" v-model="form.email" placeholder="ელ-ფოსტა" id="email">
              </div>
            </div>
            <div class="  col-lg-3  col-md-6 col-sm-12 ">


              <div class="form-check">

                <input class="form-check-input" type="radio" value="1" v-model="form.gender" name="gender" checked>
                <label class="form-check-label"> მამრობითი</label>

                <input class="form-check-input ml-4" type="radio" value="0" v-model="form.gender"  name="gender">
                <label class="form-check-label ml-5">მდემრობითი</label>
              </div>

            </div>

            <div class="  col-lg-3  col-md-6 col-sm-12">
              <h5>ფოტო 3X4</h5>
              <div class="form-group">
                <input type="file" class="form-control-file " @change="">


              </div>
            </div>

          </div>
                 <h3 class="text-center">დედა</h3>
          <div class="row">
                <div class="  col-lg-3  col-md-6 col-sm-12">
                  <div class="form-group">
                    <input type="text" class="form-control" v-model="form.firstname"  placeholder="სახელი" id="firstname">



                  </div>
                </div>
            <div class=" col-lg-3  col-md-6 col-sm-12">
              <div class="form-group">
                <input type="text" class="form-control" v-model="form.lastname" placeholder="გვარი" id="lastname">



              </div>
            </div>

            <div class=" col-lg-3  col-md-6 col-sm-12">
              <div class="form-group">
                <input type="number" class="form-control" v-model="form.momphone" placeholder="ტელეფონი" id="momphone">



              </div>
            </div>

            <div class=" col-lg-3  col-md-6 col-sm-12">
              <div class="form-group">
                <input type="text" class="form-control" v-model="form.work" placeholder="სამსახური" id="work">



              </div>
            </div>

          </div>

      <h3 class="text-center">მამა</h3>
      <div class="row">
        <div class=" col-lg-3  col-md-6 col-sm-12">
          <div class="form-group">
            <input type="text" class="form-control" v-model="form.daddname" placeholder="სახელი" id="daddname">



          </div>
        </div>
        <div class=" col-lg-3  col-md-6 col-sm-12">
          <div class="form-group">
            <input type="text" class="form-control" v-model="form.dadlname" placeholder="გვარი" id="dadlname">



          </div>
        </div>

        <div class="  col-lg-3  col-md-6 col-sm-12">
          <div class="form-group">
            <input type="number" class="form-control" v-model="form.dadphone" placeholder="ტელეფონი" id="dadphone">



          </div>
        </div>

        <div class="  col-lg-3  col-md-6 col-sm-12">
          <div class="form-group">
            <input type="text" class="form-control" v-model="form.dadwork" placeholder="სამსახური" id="dadwork">



          </div>
        </div>

      </div>
      <button type="button" class="btn btn-secondary  btn-block" @click="saveform">შენახვა</button>
    </form>
</div>


</template>


<script>
  import Multiselect from "@vueform/multiselect";
  import Editor from "@tinymce/tinymce-vue";

  export  default {
    components: { Multiselect,editor:Editor },
      data(){
        return{
          formData: new FormData(),
          form: {
            fname:null,
            lname:null,
            piradobisN:null,
            dabadebistarixi:null,
            dabadebisadgili:null,
            addres:null,
            phone:null,
            number:null,
            email:null,
            firstname:null,
            lastname:null,
            momphone:null,
            work:null,
            daddname:null,
            dadlname:null,
            dadphone:null,
            dadwork:null,
            gender:1,
            value:null,
          },
          classss:null,
          isFiled:false,

        }

      },mounted() {
      this.$store.dispatch('selectclasinfooo').then(response=>{

        this.classss=response.data



      })
    }
      ,methods:{


      getphoto(event) {
          this.formData.append('photo',event.target.files[0])
      },

      saveform(){
        this.formData.append('gender', this.form.gender)
        for (const key in this.form) {
          if(key!=='gender') {

            document.getElementById(key).style.border='1px solid black'

            if (this.form[key] == null) {

              document.getElementById(key).style.border = '1px solid red'

            } else {

              this.formData.append(key, this.form[key])
              this.isFiled = true
            }
          }

          console.log(this.form)
          }



    if(this.isFiled) {
      this.formData.append('branchID',this.$store.state.user.branchID)


      this.$store.dispatch('saveform',this.formData).then(response => {

        if (response.data.status == 'OK') {
          this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით ჩაიწერა')
        } else if (response.data.status == 'photo.ext.error') {

          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა')
        }


      })

    }




      }




    }


  }

</script>
