<template>

      <form>
          <div class="row justify-content-center mt-2 mb-5">
              <div class="col-6">
                <input type="date" class="form-control" v-model="tarigi" id="tarigi">

              </div>

          </div>
        <div class="row" v-for="(mosw,i) in moswavle " :key="i" :value="i">
              <div class="col-sm-12 col-lg-2 mt-3">
            <p>{{mosw.saxeli}} {{mosw.gvari}}({{mosw.kodi}})</p>
              </div>
          <div class=" col-sm-12  col-lg-3 mt-2">
            <input type="number"   class="form-control" v-model="point[mosw.moswavleID][2]"   placeholder="საკლასო">
          </div>

          <div class=" col-sm-12 col-lg-3 mt-2">
            <input type="number"   class="form-control"  v-model="point[mosw.moswavleID][1]"     placeholder="საშინაო">
          </div>
          <div class=" col-sm-12  col-lg-3 mt-2">
            <input type="number"   class="form-control"  v-model="point[mosw.moswavleID][3]"      placeholder="შემაჯამებელი">
          </div>



        </div>

        <button class="btn btn-secondary btn-block" type="button"  @click="savePoint">შენახვა</button>



      </form>

  <table class="table table-striped mt-2">
    <thead>
    <tr>
      <th scope="col">მოსწავლე</th>
      <th scope="col">დავალების ტიპი</th>
      <th scope="col">თარიღი</th>
      <th scope="col">ქულა</th>
      <th scope="col"></th>

    </tr>
    </thead>
    <tbody>
    <tr v-for="(table,i) in tablemosw " :key="i">

      <th scope="row">{{table.saxeli}}</th>
      <td>{{table.davaleba}}</td>
      <td><input type="date" v-model="agebuli[table.agebuliID]"></td>
      <td><input type="number" v-model="qula[table.agebuliID]"></td>
     <td><button class="btn btn-secondary btn-block" @click="updatetable(table.agebuliID,table.qula)">რედაქტირება</button></td>
    </tr>

    </tbody>
  </table>



</template>
<script>
export default {

    data(){
      return{
    moswavle:null,
        point: {},
        tarigi:null,
        tablemosw:null,
        agebuli:{},
        qula:{}
      }
    },
  mounted(){

    this.$store.dispatch('getklasiid',{klasiID:this.$route.params.klasiID}).then(response=>{
      this.moswavle=response.data

      for(const moswavle of this.moswavle) {
        this.point[moswavle.moswavleID] = {1:'',2:'',3:''}
      }

    })

    this.$store.dispatch('gettableinfo',{personID:this.$store.state.user.personaliID,paramID:this.$route.params.paramID,klasi:this.$route.params.klasi,semestriID:this.$route.params.semestriID}).then(response=>{

       this.tablemosw=response.data


      for(const tablemosws of this.tablemosw) {
        this.agebuli[tablemosws.agebuliID] = tablemosws.tarigi
      }

      for(const tablemosws of this.tablemosw) {
        this.qula[tablemosws.agebuliID] = tablemosws.qula
      }

    })
  },
  methods:{

    savePoint(){
      if(this.tarigi==null){
        document.getElementById('tarigi').style.border='1px solid red'

      }else {


        this.$store.dispatch('insertqula', {
          point: this.point,
          tarigi: this.tarigi,
          personID: this.$store.state.user.personaliID,
          paramID: this.$route.params.paramID,
          klasi: this.$route.params.klasi,
          semestriID: this.$route.params.semestriID
        })
            .then(response => {

              if (response.data.status == 'OK') {

                this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით დაემატა')

              } else {
                this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!')

              }


            })

      }
  },
    updatetable(agebuliID){

      this.$store.dispatch('updatetablee',{qula:this.qula,tarigi:this.tarigi,agebuliID:agebuliID,agebuli:this.agebuli}).then(response=>{

        if (response.data.status == 'OK') {

          this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით განახლდა')

        } else {
          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!')

        }


      })
    }
}
}
</script>