<template>
<div class="container" id="printMe">



        <div class="row justify-content-center">

          <button class=" btn btn-secondary ml-auto hidden mt-2" @click="print()">დაბეჭდვა</button>
              <div class="col-12">
                   <h1 class="text-center mt-2   ">{{tickitinfoo.ticket_name}} </h1>

                <div class="mr-auto " v-for="(qves,i) in tickitinfoo.questions" :key="i">


                  <span class="font-weight-bold ">{{qves}} ({{tickitinfoo.question_type[i]}},{{tickitinfoo.point[i]}})</span>


                <img v-if="tickitinfoo.files[i]" class="img-fluid images" :src="'https://api.ugschool.edu.ge/'+tickitinfoo.files[i]" alt=""/>
                  <div class="mt-2" v-if="tickitinfoo.instructions[i]">

                    <p>{{tickitinfoo.instructions[i]}}</p>


                    <div class="freeAnswer" style="height:300px; border:1px solid">  </div>






                  </div>


              <ol v-if="tickitinfoo.answers[i]"  >
                <li v-for="(answ,j) in tickitinfoo.answers[i]"> {{answ}} <span class="font-weight-bold hidden">-{{tickitinfoo.correct[i][j]}}</span></li>

              </ol>

                </div>
                </div>





            <hr>






        </div>


</div>

  </template>
<script>
export default {
  data(){

    return{
    tickitinfoo:{
      ticket_name:'',
      answers:{},
      correct:{},
      files:{},
      instructions:{},
      point:{},
      question_type:{},
      question_typeID:{},
      questions:{}



    },

    }
  },mounted() {
    this.$store.dispatch('gettickitinfoo',{klasiID:this.$route.params.klasiID,saganiID:this.$route.params.saganiID}).then(response=>{
      this.tickitinfoo = response.data





    })

  },
  methods:{
        print() {


          this.$htmlToPaper('printMe');


      }
  }
}
</script>
<style>


.images{
  width: 20%;
}


</style>