<template>

<div class="container">
  <div style=" font-size:50px; font-weight:bold; position: relative; left: 100%; cursor:pointer;" @click="addfield()" >+</div>
  <div class="row mt-2"  v-for="(select,$i) in selecttheme " :id="'row'+$i" >


    <div class="col-3">
      <select class="form-control" v-model="selecttheme[$i].themee">
        <option value="">აირჩიეთ თემა...</option>
        <option  :value="tick.themeID" v-for="(tick,i) in ticket" :key="i">{{tick.theme}}</option>
      </select>
    </div>
    <div class="col-3">
      <select class="form-control" v-model="selecttheme[$i].pointt">
        <option value="">აირჩიეთ ქულა...</option>
        <option :value="point.pointID"  v-for="(point,i) in Score" :key="i">{{point.point}}</option>
      </select>
    </div>
    <div class="col-3">
      <select class="form-control"  v-model="selecttheme[$i].typee">
        <option value="">აირჩიეთ ტიპი...</option>
        <option :value="qvest.question_typeID" v-for="(qvest,i) in qvestion" :key="i">{{qvest.question_type}}</option>
      </select>
    </div>
    <div class="col-2">
      <input type="number" v-model="selecttheme[$i].numberr"  class="form-control">
    </div>
    <div class="col-1">
      <span class="position-relative " @click="deletefield($i)" style="cursor: pointer">-</span>
    </div>

</div>
  <button class="btn btn-secondary p-2 mt-4" style="position: relative;left: 44%;" @click="Generate()">დაგენერირება</button>


  <modal :isOpen="ishown" id="id"  @close='ishown=false' >

    <template v-slot:body>

      <form>
        <div class="form-group">
          <label for="exampleInputEmail1">შეიყვანეთ ბილიეთის დასახელება</label>
          <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model="ticktname">
        </div>
        <button type="button" class="btn btn-secondary"  style="position: relative;left: 44%;" @click="Generateticket()">გაგძელება</button>
      </form>


    </template>

  </modal>
</div>
</template>

<script>
import modal from '@/components/modal.vue';
export default {
  data() {
    return {
      ticktname:null,
      ticket: null,
      Score:null,
      qvestion:null,
      ishown:false,
      selecttheme:[{
        themee: '',
        pointt:'',
        typee:'',
        numberr:'',
      }],

    }

  },
  components:{

    modal


  },

  mounted() {
    this.$store.dispatch('gettheme',{personID:this.$store.state.user.personaliID,saganiID:this.$route.params.saganiID,klasiID:this.$route.params.klasiID}).then(response=>{


      this.ticket=response.data



    })

    this.$store.dispatch('getScore').then(response=>{
      this.Score=response.data

    })
    this.$store.dispatch('getqvestions').then(response=>{
      this.qvestion=response.data


    })
  },methods:{
    addfield() {
      this.selecttheme.push({
        themee: '',
        pointt: '',
        typee:'',
        numberr:'',

      });


    },
    deletefield(index){

      this.selecttheme.splice(index,1)

    },
    Generate(){
      this.ishown=true

    },
    Generateticket(){

      this.selecttheme.ticket_name=this.ticktname
      this.selecttheme.klasiID=this.$route.params.klasiID
      this.selecttheme.saganiID=this.$route.params.saganiID

      this.$store.dispatch('Generateticket',{selecttheme:this.selecttheme,ticktname:this.ticktname,klasiID:this.$route.params.klasiID,saganiID:this.$route.params.saganiID}).then(response=>{

        if (response.data.status == 'OK') {

          this.$helper.notifySuccessMessage('ბილეთი წარმატებით დაგენერირდა')

        } else {
          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!')

        }

      })

    }
  }
}
</script>