<template>  
 
     <div class="row justify-content-center my-5">
          <div class="col-4">
              <form>

            <div class="form-group">
    
    <input type="text" class="form-control" placeholder="საგნის სახელწოდება" v-model="subject">
    
    </div>

         <button type="button" class="btn btn-success btn-block" @click="addSubject()">დამატება</button>

              </form>
                
          </div>

     </div>

       
 
    <Accordion @tab-open="getSubjectInfo($event)">


        <AccordionTab :header="subject.sagani"  v-for="(subject,index) in submanagement" :key="index" >

          <p class="text-right"><button @click="addteachers(subject.saganiID)" class="btn btn-warning">მასწავლებლების მიმაგრება</button></p>
          <table class="table">
 
                <tbody>
                    <tr v-for="(person,i) in subjectPerson" :key="i">
                    
                    <td>{{person.pers}}</td>
                    <td @click="deleteSubjectPerson(person.ID,i)" style="cursor:pointer;">X</td>


                    
                    </tr>
                
                </tbody>
                </table>
        </AccordionTab>


    </Accordion>



  <modal :isOpen="ishown" id="id"  @close='ishown=false' >

    <template v-slot:body>

      <Multiselect
          v-model="value"
          :options="options"
          mode="tags"
          :close-on-select="false"
          :searchable="true"
      />
      <button class="btn btn-success btn-block mt-2" @click="saveTeachersToSubject()">შენახვა</button>




    </template>

  </modal>


</template>
<script>
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import modal from '@/components/modal.vue'
import Multiselect from '@vueform/multiselect'

export default{
     data(){

         return{
              ishown:false,
             submanagement:'',

             subject:'',

             subjectPerson:'',
               value: null,
               options:null,
              sabjectForpersonSubjects:null


         }


     },

    components:{

        Accordion,AccordionTab,modal,Multiselect

    },
    mounted(){
        this.$store.dispatch('getsubjects').then(response=>{

                  this.submanagement=response.data
                       
        })

    },
    methods:{


            deleteSubjectPerson(personSubjectID,i){ 

                this.subjectPerson.splice(i,1) 


                this.$store.dispatch('deleteSubjectPerson',{personSubjectID:personSubjectID}).then(response=>{

                            if(response.data.status=='OK'){

                                     this.$helper.notifySuccessMessageWithoutRefresh('ჩანაწერი წარმატებით  წაიშალა')   

                                }else { 
                                        this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!') 

                                } 

                })


            },

      addteachers(saganiID){


                this.$store.dispatch('addteachers',{branchID:this.$store.state.user.branchID}).then(response=>{


                    this.options=response.data


                })


            this.sabjectForpersonSubjects=saganiID



        this.ishown=!this.ishown

      },
      saveTeachersToSubject(){
        this.$store.dispatch('saveteacher',{personID:this.value, subjectID:this.sabjectForpersonSubjects}).then(response=>{

          if(response.data.status=='OK'){

            this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით დაემატა')

          }else {
            this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!')

          }



        })


      },

  addSubject(){


                     
        if (this.subject.length>2){

             this.$store.dispatch('addSubject',{subject:this.subject}).then(response=>{


               

                     if(response.data.status=='OK'){

                                     this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით დაემატა')   

                                }else { 
                                        this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!') 

                                } 
                 
             }) 
                    }else {



                        this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!') 


                    }
            },


            getSubjectInfo(event){

                    

                   this.getPerson(this.submanagement[event.index].saganiID)

            },
            getPerson(saganiID){

                this.$store.dispatch('subjectPerson',{saganiID:saganiID, branchID:this.$store.state.user.branchID}).then(response=>{

                   this.subjectPerson=response.data

                })





            }

    }
   


}

 
</script>

