<template>

  <p class="my-5 text-center">სპეც მასწავლებლის მიმაგრება</p>
  <Multiselect
      v-model="value"
      :options="specpersonali"
      mode="tags"
      :close-on-select="false"
      :searchable="true"
  />
  <button type="button" class="btn btn-secondary btn-block mt-5" @click="updatespec()">მიმაგრება</button>



</template>
<script>
import Multiselect from "@vueform/multiselect";
import Editor from "@tinymce/tinymce-vue";

export default {
  components: { Multiselect,editor:Editor },
  data(){
    return{
      specpersonali:null,
      value:null
    }


  },mounted() {
    this.$store.dispatch('specpersonali').then(response=>{
      console.log(response.data)
      this.specpersonali=response.data


    })

  },methods:{
    updatespec(){




      this.$store.dispatch('updatespec',{value:this.value}).then(response=>{



        if(response.data.status=='OK'){
          this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით  შეინახა')

        }else {
          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!')

        }


      })
    }

  }

}
</script>