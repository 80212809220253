<template>
    <div class="row mt-3">
        <div class="col-12 text-right">
    <button class="btn btn-secondary" @click="getprofileInfo('education')"  >განათლების ნახვა</button>
    </div>
</div>

 

    <chu-tagz 
    
        :placeHolder="edulabel"
         :setInfo="insertEducations"
         
    />
    <div class="row mt-3">
        <div class="col-12 text-right">
    <button class="btn btn-secondary" @click="getprofileInfo('job')">სამუშაო გამოცდილების ნახვა</button>
    </div>
</div>
    <chu-tagz 
       :placeHolder="joblabel"
         :setInfo="insertJob"
    
    
    />
       <div class="row mt-3">
        <div class="col-12 text-right">
    <button class="btn btn-secondary" @click="getprofileInfo('activity')"  >დამატებითი აქტივობების ნახვა</button>
    </div>
       </div>
      <chu-tagz 
       :placeHolder="activitylabel"
         :setInfo="insertActivity"
    
    
    />
      <div class="row mt-3">
        <div class="col-12 text-right">
    <button class="btn btn-secondary" @click="getprofileInfo('training')">განვლილი ტრენინგების/აქტივობების ნახვა</button>
    </div>
      </div>
  <div></div>
         <chu-tagz 
        :placeHolder="traininglabel"
         :setInfo="insertTraining"
    
    
    />




      <FileUpload     :multiple="true" :customUpload="true" @uploader="myUploader"/>

     
  
        <modal :isOpen="ishown" id="id"  @close='ishown=false' >

            <template v-slot:body> 

            <tablezz :data="profileInfo"  :tableFields="[
                                                        {label:'ინფორმაცია',col:'profile_info'}
                                                        
                                                        ]" :filedsArrayForFilter="['profile_info']"/> 
           
            </template>

        </modal>

<div class="col-12" v-for="(img,i) in showimg " :key="i" >

  <img :src="'https://api.ugschool.edu.ge/public/hrfile/'+this.$store.state.user.personaliID+'/'+img" alt="">

</div>
</template>


<script>
 
import chuTagz from '@/components/ChuTagz.vue' 
import modal from '@/components/modal.vue'
import tablezz from '@/components/table.vue'
import FileUpload from 'primevue/fileupload';
 

 

 


export default {

    data(){
        return{

            edulabel:"განათლება" ,
            joblabel:'სამუშაო გამოცდილება',
            activitylabel:'დამატებითი აქტივობები',
            traininglabel:'განვლილი ტრენინგები/აქტივობები', 
            ishown:false,
            profileInfo:null,
          showimg:null
          
          

        }



    },  
    
    components:{

        chuTagz, modal ,tablezz,FileUpload
        

    },mounted() {
    this.$store.dispatch('showimg',{personID:this.$store.state.user.personaliID}).then(response=>{
      this.showimg=response.data

    })
  }
  ,
    methods:{

        insertEducations(education){

            this.$store.dispatch('insertEducations',{education:education}).then(response=>{ 

                
                
                    if(response.data.status=='OK'){

                            this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით დაემატა')  
                            
                            

                    }else { 
                            this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!') 

                    } 
            
            }) 


               

        },

        insertJob(job){
       this.$store.dispatch('insertJob',{job:job}).then(response=>{

           if(response.data.status=='OK'){

               this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით დაემატა')
               
           }else{

               this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!')

           }

       })
       
        },

        insertActivity(activity){
      this.$store.dispatch('insertActivity',{activity:activity}).then(response=>{

          if(response.data.status=='OK'){

              this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით დაემატა')
          }else{

              this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!')
          }

      })
            
        },

        insertTraining(training){

           this.$store.dispatch('insertTraining',{training:training}).then(response=>{
               if(response.data.status=='OK'){
                   this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით დაემატა')
               }else{

                   this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!')
               }
           })
        },
       getprofileInfo(type){


            this.$store.dispatch('getprofileInfo',{type:type,personID:this.$store.state.user.personaliID}).then(response=>{
                   

                
                    this.profileInfo=response.data
                    


            }) 
            this.ishown=!this.ishown 
             

       },
       myUploader(event){

           var formData = new FormData();
             for(let i in event.files){
                 
                 formData.append("files[]", event.files[i]);

             }
                formData.append('personID',this.$store.state.user.personaliID)
 
                   

                 this.$store.dispatch('myUploader', formData ).then(response=>{

                          if(response.data=='success'){
                            this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით აიტვირთ')
                          }else {
                            this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა')

                          }


            })


       }  
    
    },


}
</script>
  