<template>

  <div class="container">
    <p  class="text-center mt-5 font-weight-bold">მარშუტი</p>
    <div class="row my-5">
      
      <div class="col">
        <p>მძღოლი</p>
        <Multiselect
            v-model="form.value"
            :options="mdzgolii"
            mode="tags"
            :close-on-select="false"
            :searchable="true"
        />
      </div>
      <div class="col">
        <p>ნომერი</p>
        <input  class="form-control"  type="number" v-model="form.phone">
      </div>
      <div class="col">
        <p>მოსწავლე</p>
        <Multiselect
            v-model="form.moswvalue"
            :options="moswavle"
            mode="tags"
            :close-on-select="false"
            :searchable="true"
        />
      </div>
      <div class="col">
        <p>გაჩერება</p>
        <input type="text" class="form-control" v-model="form.adrres">
      </div>
      <div class="col">
        <p class="btn btn-secondary" style="margin-top: 40px;margin-left: 25px;" @click="savetransport()">შენახვა</p>
      </div>
    </div>
    <button type="button" class="btn btn-success"  @click="exel('tableID')">ექსელში გადაყვანა</button>
    <table class="table my-5" id="tableID">
      <thead>
      <tr>
        <th scope="col">მძღოლი</th>
        <th scope="col">მოსწავლე</th>
        <th scope="col">მობილური</th>
        <th scope="col">მისამართი</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(transp,i) in transportt" :key="i">
        <td>{{transp.perssaxeli}} {{transp.persgvari}}</td>
        <td> {{transp.saxeli}} {{transp.gvari}}</td>
        <td>{{transp.phone}}</td>
        <td>{{transp.moswavleadrres}}</td>
      </tr>
      </tbody>
    </table>

  </div>


</template>
<script>
import Multiselect from "@vueform/multiselect";
import Editor from "@tinymce/tinymce-vue";
export default {
  components: { Multiselect,editor:Editor },
  data(){

    return{
      formData: new FormData(),
      form:{
        value:null,
        moswvalue:null,
        phone:null,
        adrres:null
      },

      mdzgolii:null,
      moswavle:null,
      transportt:null,
      mdzgoliiID:null
    }

  },mounted() {
    this.$store.dispatch('selectmdzgoli').then(response=>{

      this.mdzgolii=response.data

    })

    this.$store.dispatch('specmoswavle').then(response=>{

      this.moswavle=response.data


    })

    this.$store.dispatch('selecttransportt').then(response=>{

      this.transportt=response.data
console.log(response.data)
    })
  },methods:{
    exel(tableID){
     this.tableToExcel(tableID)
    },
    savetransport(){
      this.mdzgoliiID= this.form.value
      this.formData.append('value', this.form.value)
      this.formData.append('moswvalue', this.form.moswvalue)
      this.formData.append('phone', this.form.phone)
      this.formData.append('adrres', this.form.adrres)

      this.$store.dispatch('insertransport',this.formData).then(response=>{

        if(response.data.status=='OK'){
          this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით  შეინახა')

        }else {
          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!')

        }


      })

    },
    tableToExcel (tableID, filename = '') {



      var downloadLink;

      var dataType = 'application/vnd.ms-excel';

      var tableSelect = document.getElementById(tableID);

      var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');



      // Specify file name

      filename = filename?filename+'.xls':'excel_data.xls';



      // Create download link element

      downloadLink = document.createElement("a");



      document.body.appendChild(downloadLink);



      if(navigator.msSaveOrOpenBlob){

        var blob = new Blob(['\ufeff', tableHTML], {

          type: dataType

        });

        navigator.msSaveOrOpenBlob( blob, filename);

      }else{

        // Create a link to the file

        downloadLink.href = 'data:' + dataType + ', ' + tableHTML;



        // Setting the file name

        downloadLink.download = filename;



        //triggering the function

        downloadLink.click();

      }



    }
  }


}

</script>