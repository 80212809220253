<template>
<p>თქვენ გაქვთ გატანილი წიგნი</p>
  <p v-for="(book,i) in pesonalinfobooks " :key="i">{{book.Title}}</p>


</template>

<script>



export default {
  data() {
    return {
      pesonalinfobooks:null

    }


  }, mounted() {
    this.$store.dispatch('pesonalinfobook',{personID:this.$store.state.user.personaliID}).then(response => {

      this.pesonalinfobooks=response.data
      console.log(response.data)
    })


  }
}


</script>