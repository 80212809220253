<template>
<div class="container">
  <div class="col-12">
    <form class="mt-5">
      <div class="form-row">
        <div class="col-4">
          <select class="form-control" id="exampleFormControlSelect1" v-model="form.class" >
            <option value="0">აირჩიეთ კლასი</option>
    <option v-for="(day,i) in days " :key="i">{{day}} </option>
  </select>
  </div>
    <div class="col">
      <Multiselect
          placeholder="აირჩიეთ მოსწავლე"
          v-model="form.value"
      :options="longlesson"
      mode="tags"
      :close-on-select="false"
      :searchable="true"/>
    </div>
    <div class="col">
      <Multiselect
          placeholder="აირჩიეთ მასწავლებელი"
          v-model="form.teacherID"
          :options="teacher"
          :close-on-select="false"
          :searchable="true"/>
  </div>
  </div>
    <button type="button" class="btn btn-secondary btn-block mt-5" @click="savelonglessons">შენახვა</button>
  </form>

  </div>


  <table class="table my-5">
    <thead>
    <tr>
      <th scope="col">კლასი</th>
      <th scope="col">მოსწავლე</th>
      <th scope="col">მასწავლებელი</th>

    </tr>
    </thead>
    <tbody>
    <tr v-for="(tbale,i) in tableinfo " :key="i">
      <td>{{tbale.klasi}} კლასი</td>
      <td>{{tbale.moswsaxeli}} {{tbale.moswgvari}}</td>
      <td>{{tbale.saxeli}}  {{tbale.gvari}}</td>
    </tr>
    </tbody>
  </table>


  </div>



  </template>
    <script>
      import Multiselect from "@vueform/multiselect";
      import Editor from "@tinymce/tinymce-vue";

      export default {
      components: { Multiselect,editor:Editor },
      data(){

    return{
      formData: new FormData(),
      form:{
        value:null,
        class:0,
        teacher:0,
        teacherID:null

      },
      days: {1:'1',2:'2',3:'3',4:'4',5:'5',6:'6',7:'7',8:'8',9:'9',10:'10',11:'11',12:'12'},
  longlesson:null,
      teacher:null,
      tableinfo:null,


    }

  },mounted() {

    this.$store.dispatch('selectlonglesson').then(response => {

      this.longlesson=response.data

    })
          this.$store.dispatch('selecttableinfogaxangdzlivebuli').then(response => {

            this.tableinfo=response.data
            console.log(response.data)

          })

  this.$store.dispatch('selectteacherss').then(response => {
    this.teacher=response.data


  })
},methods:{
    savelonglessons(){
      this.formData.append('teacherID', this.form.teacherID)
      this.formData.append('value', this.form.value)
      this.formData.append('class', this.form.class)

      this.$store.dispatch('savelonglessons', this.formData).then(response => {
        if (response.data.status == 'OK') {
          this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით  შეინახა')

        } else {
          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!')

        }

      })
    }

  }

}

</script>