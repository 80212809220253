<template>

<div class="container">
  <div class="row">
    <div class="col-3 ">
     <p class="font-weight-bold">სპეც მასწავლებლები</p>
<div v-for="(spec,i) in specpersonalilist " :key="i" @click="info(spec.personaliID)" style="cursor: pointer" class="my-2 specpersonal p-2 rounded rounded-1">{{spec.saxeli}} {{spec.gvari}}</div>
    </div>
    <div class="col-3 mt-2" v-show="hidden">
      <div class="form-group">
        <label> მოსწავლეები</label>
        <Multiselect
            v-model="form.value"
            :options="specmoswavle"
            mode="tags"
            :close-on-select="false"
            :searchable="true"
        />
      </div>

    </div>
    <div class="col-2 mt-2" v-show="hidden">
      <label> დაწყების დრო</label>
          <input type="date" class="form-control" placeholder="დაბადების თარიღი" v-model="form.startdate">
    </div>
    <div class="col-2 mt-2" v-show="hidden">
      <label> დასრულების დრო</label>
      <input type="date" class="form-control" placeholder="დაბადების თარიღი" v-model="form.endtdate">
    </div>
    <div class="col-2 mt-2">
      <button  v-show="hidden" type="button" class="btn btn-secondary btn-block "  data-toggle="modal" data-target="#modal" style="margin-top: 31px" @click="next()">გაგძელება</button>
    </div>
  </div>
  <div class="modal fade" id="modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <label for="start">დაწყების დრო</label>
          <input type="time" id="start" name="start"  v-model="form.startdatee">
          <label for="end" >დასრულების დრო</label>
          <input type="time" id="end" name="end" v-model="form.endtdatee">
          <Multiselect class="my-4"
              v-model="form.valueday"
              :options="days"
              :close-on-select="false"
              :searchable="true"
          />

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">დახურვა</button>
          <button type="button" class="btn btn-primary" @click="nextmuveee()">შენახვა</button>
        </div>
      </div>
    </div>
  </div>

  <p class="text-center font-weight-bold my-5">ბავშვებთან მუშაობის ცხრილი</p>
  <div class="row">
    <div class="col-6">
      <div v-for="(specc,i) in specpersonalilist " :key="i" @click="infotablee(specc.personaliID)" style="cursor: pointer" class="my-3 btn-spec rounded rounded-2 p-2"> {{specc.saxeli}} {{specc.gvari}}</div>
    </div>
    <div class="col-6">
<div   v-for="(info,i) in infotable " :key="i" class="info-table-bg mt-3 rounded rounded-2"><p class="text-center">{{info.saxeli}}  {{info.gvari}}</p>
<div class="ml-3 font-weight-bold">
  {{info.dge}}
</div>
  <div class="ml-2">
    {{info.start_time}}   {{info.moswsaxeli}} {{info.moswgvari}}
  </div>




</div>
    </div>
  </div>
</div>



</template>
<script>
import Multiselect from "@vueform/multiselect";
import Editor from "@tinymce/tinymce-vue";
export default {
  components: { Multiselect,editor:Editor },
  data(){

    return{
      formData: new FormData(),
      form:{
        startdate:null,
        endtdate:null,
        value:null,
        presonaliID:null,
        startdatee:null,
        endtdatee:null,
        valueday:null,
      },
      infotable:null,
      specpersonalilist:null,
      hidden:false,
      specmoswavle:null,
      days: {1:'ორშაბათი',2:'სამშაბათი',3:'ოთხშაბათი',4:'ხუთშაბათი',5:'პარასკევი',6:'შაბათი',7:'კვირა'},

      nextmuve:null,
    }
  },mounted() {
    this.$store.dispatch('specpersonalilist').then(response=>{

      this.specpersonalilist=response.data


    })

    this.$store.dispatch('specmoswavle').then(response=>{

      this.specmoswavle=response.data


    })

  },methods:{

    info(personaliID){
      this.hidden=true
      this.form.presonaliID=personaliID




    },
    next(){

      this.formData.append('startdate', this.form.startdate)
      this.formData.append('endtdate', this.form.endtdate)
      this.formData.append('value', this.form.value)
      this.formData.append('presonaliID', this.form.presonaliID)

      this.$store.dispatch('specinfoinsert',this.formData).then(response=>{




      })
    },
    nextmuveee(){
      this.formData.append('startdatee', this.form.startdatee)
      this.formData.append('endtdatee', this.form.endtdatee)
      this.formData.append('valueday', this.form.valueday)
      this.$store.dispatch('nextmuve',this.formData).then(response=>{
        if(response.data.status=='OK'){
          this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით  შეინახა')

        }else {
          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!')

        }







        this.nextmuve=response.data


      })

    },
    infotablee(ID){

      this.$store.dispatch('infotableeeeeee',{ID:ID}).then(response=>{

        this.infotable=response.data


      })

    }


  }
}
</script>