<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <form class="mt-5">
          <div class="form-row">
            <div class="col-4">
              <input type="text" class="form-control" placeholder="მენიუ" v-model="form.menu">
            </div>
            <div class="col">
              <select class="form-control" id="exampleFormControlSelect1"  v-model="form.dayID">
                <option value="0">აირჩიეთ დღე</option>
                <option v-for="(day,i) in days " :key="i" :value="day.ID">{{day.dge}}</option>
              </select>
            </div>
            <div class="col">
              <select class="form-control" id="exampleFormControlSelect1" v-model="form.selectmenu">
                <option value="0">მენიუ პატარებისთვის</option>
                <option value="1">მენიუ დიდიებისთვის</option>
              </select>
            </div>
            <div class="col">
              <Multiselect
                  placeholder="აირჩიეთ კლასი"
                  v-model="form.klassvalue"
                  :options="classss"
                  :close-on-select="false"
                  :searchable="true"/>
            </div>
          </div>
          <button type="button" class="btn btn-secondary btn-block mt-5" @click="insertfoodmenu()">შენახვა</button>
        </form>

      </div>

    </div>
    <table class="table mt-5">
      <thead>
      <tr>
        <th scope="col">მენიუ</th>
        <th scope="col">დღე</th>
        <th scope="col">დიდიებისთვის/პატარებისთვის</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(foods,i) in food " :key="i" :value="i">
        <th scope="row">{{foods.menu}}</th>
        <td>{{foods.dge}}</td>
        <td>{{foods.foradults}}</td>
      </tr>
      </tbody>
    </table>

  </div>

</template>

<script>
import Multiselect from "@vueform/multiselect";
import Editor from "@tinymce/tinymce-vue";

export default {
  components: { Multiselect,editor:Editor },
  data() {
    return {
      formData: new FormData(),
      form: {
        selectmenu: 0,
        menu: null,
        dayID: 0,
        klassvalue:null,
      },
      food:null,
      classss:null,

days:null
    }

  }, methods: {

    insertfoodmenu() {
      this.formData.append('dayID', this.form.dayID)
      this.formData.append('klassvalue', this.form.klassvalue)
      this.formData.append('selectmenu', this.form.selectmenu)
      this.formData.append('menu', this.form.menu)
      this.formData.append('datee', this.form.datee)
      this.$store.dispatch('insertfoodmenu', this.formData).then(response => {
        if (response.data.status == 'OK') {
          this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით  შეინახა')

        } else {
          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!')

        }
      })


    }


  }, mounted() {
    this.$store.dispatch('selectfoodcalendar',{personID:this.$store.state.user.personaliID}).then(response => {

      this.food=response.data
    })
    this.$store.dispatch('selectclasinfooo').then(response=>{

      this.classss=response.data


    })


    this.$store.dispatch('selectdays').then(response => {


      this.days=response.data


    })

  }

}

</script>