<template>

  <div class="container-fluid">

        <div class="row mt-2">
            <div class="col-sm-12 col-md-7 col-lg-7">

            <div class="form-group">

              <input type="text" class="form-control" placeholder="პროფესია" id="text" v-model="form.profesia">

            </div>

            </div>
          <div class="col-sm-12 col-md-4 col-lg-4">

            <div class="form-group">

              <select class="form-control" id="select" v-model="form.profesiatypeID">

                <option value="0">პროფესიის ტიპი</option>
                <option v-for="(selectmenegment,i) in profesions" :="i" :value="selectmenegment.ID">{{selectmenegment.profesia_type}}</option>


              </select>

            </div>

          </div>
          <div class="col-sm-12 col-md-1 col-lg-1 text-center">
            <button type="button" class="btn btn-secondary" @click="savemenegment">შენახვა</button>
          </div>

              <div class="col-12 mt-2">
          <table class="table">
            <thead>
            <tr>
              <th scope="col">პროფესია</th>
              <th scope="col">პროფესიის ტიპი</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(proff,i) in tableinfo" :key="i" >
              <td>{{proff.profesia}}</td>
              <td>{{proff.profesia_type}}</td>

            </tr>
            </tbody>
          </table>


        </div>

        </div>

  </div>
</template>
<script>
export default {
  data(){
    return {
      form: {
        profesia: null,
        profesiatypeID: 0,

      },
      profesions: null,
      tableinfo:null,
    }
  },mounted() {
    this.$store.dispatch('getmenegment').then(response=>{

      this.profesions=response.data

    })
    this.$store.dispatch('showinfo').then(response=>{
      this.tableinfo=response.data


    })
  },methods:{

    savemenegment(){
      this.$store.dispatch('savemenegment',this.form).then(response=>{

        if(response.data.status=='OK'){

          this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით დაემატა')



        }else {
          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!')

        }



      })



    }

  }


}


</script>
