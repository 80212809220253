<template>


  <div class="container-fluid">
    <div class="border-bottom">
    <div class="row">


      <div class="col-sm-12 col-md-4  ">
        <img src="../assets/image/staff/3.jpg" class="img  images mx-auto d-block rounded-circle" alt="">
        <h4 class=" mt-3 font-weight-bold text-center">
          <span>{{this.$store.state.personditeil.saxeli}} {{this.$store.state.personditeil.gvari}}</span>
        </h4>


      </div>

      <div class="col-sm-12 col-md-8 mt-5">

        <p>
         <span class="font-weight-bold">პირადობის N: </span>  {{this.$store.state.personditeil.piradi_num}}
        </p>
        <p>
         <span class="font-weight-bold">მობილური:</span>  {{this.$store.state.personditeil.mobiluri}}
        </p>
        <p>
         <span class="font-weight-bold"> მისამართი:</span> {{this.$store.state.personditeil.misamarTi}}
        </p>
        <p>
        <span class="font-weight-bold">  ელფოსტა:</span>  {{this.$store.state.personditeil.elfosta}}
        </p>

      </div>



    </div>
      </div>


    <div class="row justify-content-center">



      <table class="table table-striped table-responsive-md mt-3" @click="updatemoswinfo()">
        <thead>
        <tr>
          <th scope="col">სტატუსი</th>
          <th scope="col">ბრძანების#</th>
          <th scope="col">ბრძანების თარიღი</th>
          <th scope="col">გადასახადი</th>
          <th scope="col">კონტქტის#</th>
          <th scope="col">კონტქტის დაწყების თარიღი</th>
          <th scope="col">კონტქტის დასრულების თარიღი</th>
          <th scope="col">გახანგრძლივებული</th>
          <th scope="col">მიზეზი</th>
          <th scope="col">ქმედება</th>
          <th scope="col">ქმედების აღწერა</th>
          <th scope="col">შეღავათი</th>
          <th scope="col">წლის დასასრული</th>


        </tr>
        </thead>
        <tbody>
        <tr v-for="(contract,i) in this.$store.state.personditeil.contracts" :key="i">

          <th scope="row">{{contract.status_type}}</th>
          <td>{{contract.order_number}}</td>
          <td>{{contract.order_date}}</td>
          <td>{{contract.fee}}</td>
          <td>{{contract.contract_number}}</td>
          <td>{{contract.contract_start_date}}</td>
          <td>{{contract.contract_end_date}}</td>
          <td>{{contract.gaxangrdzlivebuli_fee}}</td>
          <td>{{contract.mizezi}}</td>
          <td>{{contract.action}}</td>
          <td>{{contract.action_description}}</td>
          <td>{{contract.shegavati}}</td>
          <td><button>რედაქტირება</button></td>
        </tr>
        </tbody>
      </table>

      <h4 class="text-center mt-3 font-weight-bold">მშობლები</h4>

      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">სახელი</th>
          <th scope="col">გვარი</th>
          <th scope="col">მობილური</th>
          <th scope="col">სამსახური</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(familly,i) in this.$store.state.personditeil.family" :key="i">
          <th scope="row">{{familly.name}}</th>
          <td>{{familly.surname}}</td>
          <td>{{familly.mob}}</td>
          <td>{{familly.work_place}}</td>
        </tr>

        </tbody>
      </table>





    </div>




  </div>

</template>

<style scoped>
.images{
  width: 150px;
  height: 150px;
  margin-top: 15px;
}
</style>

<script>
export default {
  data(){

    return{
      update:null
    }
  },methods:{

    updatemoswinfo(){
      this.$store.dispatch('updatemoswinfo').then(response=>{

        this.update=response.data

      })
    }
  }
}

</script>