import {createApp } from 'vue'

import VueHtmlToPaper from 'vue-html-to-paper';
import App from './App.vue'
import router from './router'
import store from './store'
import helper from './helper'
import PrimeVue from 'primevue/config';
import VCalendar from 'v-calendar';


 


 
 

 


import './assets/css/main.css'
import './assets/css/bootstrap.min.css'

import './assets/css/style.css'
import 'v-calendar/dist/style.css';


window.$ = window.jQuery = require('jquery');

 


import './assets/js/bootstrap.bundle.min'



const appvue=createApp(App)
appvue.use(store)
appvue.use(router)
appvue.use(PrimeVue)
appvue.use(VueHtmlToPaper)
appvue.use(VCalendar,{})
 
appvue.mount('#app')



appvue.config.globalProperties.$helper = helper