<template>
<div class="container">
  <div class="row">
    <div class="col-4">

      <Multiselect
          placeholder="აირჩიეთ კლასი"
          v-model="form.klassvalue"
          :options="classss"
          :close-on-select="false"
          :searchable="true"/>

    </div>
    <div class="col-4">
      <Multiselect
          placeholder="აირჩიეთ დამრიგებელი"
          v-model="form.value"
          :options="damrigebeli"
          :close-on-select="false"
          :searchable="true"/>
          </div>
          <div class="col-4">
            <button type="button" class="btn btn-primary" @click="savedamrigebeli()">შენახვა</button>
          </div>
        </div>





  <div class="row">
    <div class="col-4">
    <p class="mt-5 font-weight-bold">კლასები</p>
      <div v-for="(classs,i) in klasiii" :key="i" @click="getclassinfo(classs.klasiID)" style="cursor: pointer">
        {{classs.klasi}}
      </div>
    </div>

    <div class="col-8">
      <p class="mt-5 font-weight-bold">დამრიგებელი</p>
<div v-for="(pro,i) in profrsia " :key="i" @click="moswavlefunction(pro.klasiID)"  style="cursor: pointer">
  {{pro.saxeli}} {{pro.gvari}}
</div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
      <div class="mt-2 ml-2" style="cursor: pointer"  @click="moswprofile(stu.classID)"  v-for="(stu,i) in students " :key="i">{{stu.mosw}}</div>
    </div>
  </div>

  <table class="table my-5" v-show="hidden">
    <thead>
    <tr>

      <th scope="col">სახელი</th>
      <th scope="col">გვარი</th>
      <th scope="col">საგანი</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(moswpro,i) in moswprofilee " :key="i" scope="row">
      <td>{{moswpro.saxeli}}</td>
      <td>{{moswpro.gvari}}</td>
      <td>{{moswpro.sagani}}</td>

    </tr>
    </tbody>
  </table>
  <table class="table my-5" v-show="hidden">
    <thead>
    <tr>

      <th scope="col">დღე</th>
      <th scope="col">მენიუ</th>
      <th scope="col">დიდიებისთვის/პატარებისთის</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(foodd,i) in food " :key="i" scope="row">
      <td>{{foodd.dge}}</td>
      <td>{{foodd.menu}}</td>
      <td>{{foodd.foradults}}</td>

    </tr>
    </tbody>
  </table>
  <table class="table my-5" v-show="hidden">
    <thead>
    <tr>

      <th scope="col">დღე</th>
      <th scope="col">საგანი</th>
      <th scope="col">კლასი</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(table,i) in cxrili " :key="i" scope="row">
      <td>{{table.dge}}</td>
      <td>{{table.sagani}}</td>
      <td>{{table.klasi}}</td>

    </tr>
    </tbody>
  </table>
  <table class="table my-5" v-show="hidden">
    <thead>
    <tr>

      <th scope="col">აღწერა</th>
      <th scope="col">თარიღი</th>
      <th scope="col">კლასი</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(calenda,i) in calendar " :key="i" scope="row">
      <td>{{calenda.Description}}</td>
      <td>{{calenda.Date}}</td>
      <td>{{calenda.klasi}}</td>

    </tr>
    </tbody>
  </table>
</div>










</template>
<script>
import Multiselect from "@vueform/multiselect";
import Editor from "@tinymce/tinymce-vue";

export default {
  components: { Multiselect,editor:Editor },
  data(){
    return{
      formData: new FormData(),
      form:{
        value:null,
        klasii:0,
        klassvalue:null
      },
      classss:null,
      profrsia:null,
      students:null,
      damrigebeli:null,
      klasiii:null,
      moswprofilee:null,
      hidden:false,
      food:null,
      cxrili:null,
      calendar:null



    }


  },mounted() {
    this.$store.dispatch('selectclasinfooo').then(response=>{

      this.classss=response.data
      console.log(response.data)


    })
    this.$store.dispatch('selectclasmenu').then(response=>{

      this.klasiii=response.data


    })
    this.$store.dispatch('selectdamrigeblebi').then(response=>{

      this.damrigebeli=response.data



    })


  },methods:{
    getclassinfo(clasiID){

      this.$store.dispatch('selectprofesia',{clasiID:clasiID}).then(response=>{

        this.profrsia=response.data


      })
      },
    moswprofile(classID){
      this.$store.dispatch('selectmoswprofile',{classID:classID}).then(response=>{
  if(this.hidden==false){
  this.hidden=true
  }else {
  this.hidden=false
  }


        this.moswprofilee=response.data


      })
      this.$store.dispatch('selectfood',{classID:classID}).then(response=>{

        this.food=response.data


      })
      this.$store.dispatch('selecttableforstudents',{classID:classID}).then(response=>{
        console.log(response.data)
        this.cxrili=response.data


      })
      this.$store.dispatch('selecteventcalendarforstudents',{classID:classID}).then(response=>{
        console.log(response.data)
        this.calendar=response.data


      })

    },
    moswavlefunction(klasiID){
      this.$store.dispatch('selectstudentsss',{klasiID:klasiID}).then(response=>{

        this.students=response.data

      })
    },
    savedamrigebeli(){

      this.formData.append('value', this.form.value)
      this.formData.append('klassvalue', this.form.klassvalue)
      this.$store.dispatch('selectdamrigebeli',this.formData).then(response=>{


        if(response.data.status=='OK'){
          this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით  შეინახა')

        }else {
          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!')

        }



      })

    }
  }

}
</script>