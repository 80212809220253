<template>
<div class="container">
  <div class="row">
    <div class="col-3 mt-2" v-for="(point,i) in Score " :key="i" :value="i">
<div @click="getqvestions(point.pointID)" style="cursor: pointer">{{point.point}}</div>
    </div>
<div class="col-12 mt-5">
<div @click="getform(kitxva.question_typeID)" v-for="(kitxva,i) in qvestion " :key="i" :value="i" style="cursor: pointer">{{kitxva.question_type}}</div>
</div>
  </div>
</div>
<div class="container">
  <div class="row">
<div class="col-12">
  <form v-show="showForm" class="mt-5">
    <div class="form-row">
      <div class="col-10">
        <label for="text">კითხვა</label>
        <input type="text" class="form-control"  v-model="qvestions" id="text">
      </div>
      <div class="col">
        <div class="form-group">
          <input type="file"  class="form-control-file mt-4"  @change="getphoto">
        </div>
      </div>

    </div>
    <div v-show="showFormm">
    <p class="mt-5 text-center">შეფასების კრიტერიუმები</p>
    <div class="form-group">
      <textarea class="form-control" v-model="textareaa" d="textarea" rows="10"></textarea>
    </div>
    <button @click="saveform()" class="btn btn-secondary btn-block" type="button">შენახვა</button>
    </div>
    <div v-show="showtextare">
      <div class="col-4 text-center mx-auto mt-5">
        <div class="form-group position-relative mx-sm-10 mb-2">
          <label for="texttt">სავარაუდო პასუხები</label>
          <div id="parent">

            <span class="position-relative position" @click="addfield()"  >+</span>

            <div v-for="(answer, $i) of answers" :id="'row'+$i">
              <input v-model="answers[$i].text" type="text" class="form-control" :id="'field'+$i" >
              <input class="form-check-input check position-relative" type="checkbox" value="" v-model="answers[$i].is_correct" :id="'checkboxField'+$i" >
              <span class="position-relative position" @click="deletefield($i)"  >-</span>
            </div>

          </div>


        </div>
        <button @click="saveform()" class="btn btn-secondary btn-block" type="button">შენახვა</button>
      </div>
    </div>

  </form>
  </div>
  </div>
</div>
  <table class="table table-striped my-5">
    <thead>
    <tr>
      <th scope="col">კითხვა</th>
      <th scope="col">კითხვის ტიპი</th>
      <th scope="col">ქულა</th>
      <th scope="col">ფაილი</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(Scor,i) in Scoreinfo " :key="i" @click="showanswers(Scor.question_typeID,Scor.questionID)" style="cursor: pointer">
      <th scope="row">{{Scor.question}}</th>
      <td>{{Scor.question_type}}</td>
      <td>{{Scor.point}}</td>
      <td><a target="_blank" :href="'https://api.ugschool.edu.ge/'+Scor.files">{{Scor.files}}</a></td>
    </tr>
    </tbody>
  </table>
  <modal :isOpen="ishown" id="id"  @close='ishown=false' >

    <template v-slot:body>

      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">პასუხი</th>
          <th scope="col">სწორი/არასწორი</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(getansw,i) in getanswers " :key="i">
          <th scope="row">{{getansw.answer}}</th>
          <td>{{getansw.correct==1?'სწორია':'არასწორია'}}</td>

        </tr>
        </tbody>
      </table>


    </template>

  </modal>
</template>

<style>
.check{
  bottom: 37px;
  left: 195px;
  cursor: pointer;
}
.position{
  bottom: 38px;
  left: 200px;
  font-size: 23px;
  cursor: pointer;
}




</style>
<script>
import modal from '@/components/modal.vue';



export default {

  data(){

    return{
      formData: new FormData(),
      Score:null,
      qvestion:null,
      showForm: false,
      showFormm:false,
      showtextare:false,
      filedIndex:1,
      answersCount: [],
      answers:[{
        text: '',
        is_correct: ''
      }],
      questio_type:null,
      point:null,
       qvestions:null,
       textareaa:null,
       forminfo:null,
      Openquestion:null,
      checkbos:[],
      Scoreinfo:null,
      getanswers:null,
      ishown:false
    }

  },
  components:{

   modal


  },
  mounted() {
    this.$store.dispatch('getScore').then(response=>{
      this.Score=response.data

    }),
        this.$store.dispatch('Scoreinfo',{themeID:this.$route.params.themeID,personID:this.$store.state.user.personaliID}).then(response=>{
          this.Scoreinfo=response.data


        })

  },methods:{
    getphoto(event){

       this.formData.append('photo',event.target.files[0])
    },
    saveform() {
      this.formData.append('Openquestion', this.Openquestion)
      this.formData.append('qvestions',this.qvestions)
      this.formData.append('textareaa',this.textareaa)
      this.formData.append('pointID',this.point)
      this.formData.append('question_typeID',this.questio_type)
      this.formData.append('themeID',this.$route.params.themeID)
      this.formData.append('personID',this.$store.state.user.personaliID)

      // for(const chck of this.checkbos) this.formData.append('checkbos[]', chck)
      for(const i in this.answers) {
        this.formData.append('answers['+i+'][text]', this.answers[i].text)
        this.formData.append('answers['+i+'][is_correct]', this.answers[i].is_correct)
      }

      this.$store.dispatch('insertforminfo',this.formData).then(response=>{

        if(response.data.status=='OK'){
          this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით დაემატა')
        }else if(response.data.status=='error'){

          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა')
        }

      })

    },

    getqvestions(point){
      this.$store.dispatch('getqvestions').then(response=>{
        this.qvestion=response.data


      })
      this.point=point
    },
    getform(kitxva){
      if(kitxva==1){
        this.showForm = true
        this.showFormm = true
        this.showtextare=false
      }else {
        this.showForm = true
       this.showtextare=true
        this.showFormm = false

      }

    this.questio_type=kitxva
    },
    showanswers(questio_typeID,questionID){
      if (questio_typeID==2) {
        this.$store.dispatch('getanswers',{questionID:questionID,personID:this.$store.state.user.personaliID}).then(response => {
          this.getanswers = response.data

        })
        this.ishown=!this.ishown
      }
    },


    addfield(){
      this.answers.push({
        text: '',
        is_correct: ''
      });

    },
    deletefield(index){

      this.answers.splice(index,1)

    }

  }
}
</script>