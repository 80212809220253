<template>
     
<div class="container-fluid">
 <div class="row justify-content-center align-items-center login-form">
   <div class="col-12 col-sm-8 col-md-5 col-lg-3 border   rounded shadow-lg   form-container">
            
       <div class="my-4">
           <img src="@/assets/image/logo.png" class="img-fluid rounded-circle d-block mb-3 mx-auto logo-brand" >

          <h5 class="text-center mb-3">UG School ონლაინ სისტემა</h5> 
        <form  @submit.prevent="$store.dispatch('login',{code:this.code,password:this.password})"  > 

        <div class="input-group ">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class='bx bx-user'></i></span>
                </div>
            <input type="text" class="form-control"  placeholder="კოდი" v-model="code" aria-describedby="basic-addon1">

      </div>


        <div class="input-group mt-3">
             <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon2"><i class='bx bx-lock' ></i></span>
                </div>

              <input type="password" class="form-control" v-model="password"   placeholder="პაროლი" aria-describedby="basic-addon2">
        </div>
            
            <button  class="btn btn-block btn-secondary mt-3">შესვლა</button>  
        </form>
        <div class="row align-items-center justify-content-center copy">

            <div class="col-11">
              <p class="text-center">&copy; ყველა უფლება დაცულია. <a href="https://ugschool.edu.ge">ugschool.edu.ge</a> </p>
            </div>

        </div>


       </div>





        </div>

        
        </div>


        </div> 

       
</template> 

 <script>
  
 
 export default  {
     data() {
         return{
             code:'',
             password:''


         }
     } 
 } 
 </script>
 