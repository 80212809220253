<template>
 <div class="container">
   <div class="row  justify-content-center">
      <div class=" col-sm-12 col-md-6  mt-3">
        <div class="form-group">
        <Multiselect
            placeholder=""
            :filter-results="false"
            :min-chars="3"
            :resolve-on-load="false"
            :delay="0"
            :searchable="true"
            @select="chosensubjectandklass"
            :options="getklassandsubject"/>

        </div>
        <form action="">
              <div class="form-group ">

                <input type="date" class="form-control" v-model="homeworkform.dateinfo" id="dateinfo">

              </div>
          <div class="form-group">
            <textarea class="form-control" rows="3" v-model="homeworkform.textareaa" id="textareaa"></textarea>
          </div>

            <button class="btn btn-secondary btn-block" @click="saveklassandsubject()"> შენახვა</button>

        </form>




      </div>
            </div>
        </div>


  <table class="table table-striped mt-4">
    <thead>
    <tr>
      <th scope="col">საგანი</th>
      <th scope="col">კლასი</th>
      <th scope="col">საშინაო დავალება</th>
      <th scope="col">საშინაო დავალების თარიღი</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(klassubj,i) in klasandsubject " :key="i" :value="i">
      <th scope="row">{{klassubj.sagani}}</th>
      <td>{{klassubj.klasi}}</td>
      <td>{{klassubj.homework}}</td>
      <td>{{klassubj.homework_date}}</td>
      <td><button class="btn btn btn-outline-secondary" @click="deletsub(klassubj.homeworkID)">წაშლა</button></td>
    </tr>
    </tbody>
  </table>



</template>

<script>
import Multiselect from '@vueform/multiselect'

export default {

  components: {Multiselect},
  data() {
    return {
      klasandsubject:null,
      chose:null,
      getklassandsubject:null,
      homeworkform:{
        dateinfo:null,
        textareaa:null,

      }

    }


  }, methods: {
    chosensubjectandklass(option) {
        this.chose=option
    },
    saveklassandsubject(){
      this.homeworkform.paramID=this.chose
      this.homeworkform.branchID=this.$store.state.user.branchID
      this.$store.dispatch('saveklassandsubject',this.homeworkform).then(response=>{

        if(response.data.status=='OK'){
          this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით დაემატა ')
        }else if(response.data.status=='photo.ext.error'){

          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა')
        }
      })

    },
    deletsub(homeworkID) {

      if (confirm('ნამდვილად გსურთ წაშლა?')) {
        this.$store.dispatch('deletsub',{ID: homeworkID}).then(response => {


          if (response.data.status == 'OK') {
            this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით წაიშალა')
          } else if (response.data.status == 'ERROR') {

            this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა')
          }


        })
      }
    }

  },mounted() {

    this.$store.dispatch('getklassandsubject',{personID:this.$store.state.user.personaliID}).then(response=>{


      this.getklassandsubject=response.data


    })
    this.$store.dispatch('klasandsubject',{personID:this.$store.state.user.personaliID}).then(response=>{


       this.klasandsubject=response.data



    })
  }
}

</script>