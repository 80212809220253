<template>
    

    <div class="row mt-3   custom-height  ">

        <div class="col-12 rounded col-bg">
           
                <div class="row mt-4">
                    <div class="p-2 bg-secondary ml-1 rounded text-white" v-for="(tag,i) in tags" :key="i">

                        {{tag}} 

                     <span role="button" @click="deleteTag(i)">x</span>   
                </div>
                <div class="col">

                         <input type="text" class="tagz-input" :placeholder="placeHolder" @keydown="addTag" >

                </div>

                </div>
                 
                
            
        </div>

    </div>

<button @click="setInfo(tags)" class="btn btn-light mt-3 btn-block"> შენახვა </button>
</template>


<style scoped>
.col-bg{

    background-color: rgb(173, 217, 255);

}
.tagz-input{

    width: 20%;

    background-color: rgb(173, 217, 255);
    border:none;
    border-bottom: 1px solid black;
    outline: none;

}

.custom-height{

    height: 100px;
     

}

</style>

<script>
export default {
    
    data(){

        return{

            tags:[],
           

        }

    },
    props:['placeHolder','setInfo'],

    methods:{

        addTag(event){

                if(event.code=="Enter"){

                     let data=event.target.value.trim()
                     this.tags.push(data)
                    
                        event.target.value=''


                }

        },

        deleteTag(index){

            this.tags.splice(index,1)


        }


    }  

}
</script>