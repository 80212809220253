import axios from 'axios'
import { createStore } from 'vuex'
 

 

export default createStore({
  state: { 

      user:'',
      sanctummToken:'',
      personditeil:'',
      klasi:''


  },
  mutations: {

        loginMutations(state,responseData){

              state.user=responseData
              state.sanctummToken=responseData.token 
 
              

        },
 
  },
  actions: {  

      login({commit}, {code,password} ){

        
            axios.post('https://api.ugschool.edu.ge/public/api/authorization',{code,password}).then(response=>{


                  commit('loginMutations',response.data)
                  localStorage.setItem('token',response.data.token)
                  window.location.href='/'
               


            })



      },

      getinfoOnRefresh({commit}){

        axios.get('https://api.ugschool.edu.ge/public/api/getinfoOnRefresh').then(response=>{


          commit('loginMutations',response.data)
       


    }) 



      },

      logout(){ 


        axios.get('https://api.ugschool.edu.ge/public/api/logout').then(response=>{


          localStorage.removeItem('token')


          window.location.href='/#/login'

        })  

         

      } , 

      insertEducations(state,{education}){ 

            let jsonedInfo=JSON.stringify(education) 
           return axios.post('https://api.ugschool.edu.ge/public/api/saveProfileInfo',{jsonedInfo:jsonedInfo,type:'education'}) 
 
      },
      insertJob(state,{job}){
          let jsonedInfo=JSON.stringify(job)
          return axios.post('https://api.ugschool.edu.ge/public/api/saveProfileInfo',{jsonedInfo:jsonedInfo,type:'job'})



      },
       insertActivity(state,{activity}){

        let jsonedInfo=JSON.stringify(activity)
        return axios.post('https://api.ugschool.edu.ge/public/api/saveProfileInfo',{jsonedInfo:jsonedInfo, type:'activity'})
       },

       insertTraining(state,{training}){
          let jsonedInfo=JSON.stringify(training)
          return axios.post('https://api.ugschool.edu.ge/public/api/saveProfileInfo', { jsonedInfo:jsonedInfo, type:'training'})

       },

       getprofileInfo(state,{type,personID}){

         return axios.post('https://api.ugschool.edu.ge/public/api/getProfileInfo',{type,personID})

       },
       myUploader(state,  formData ){
        return axios.post('https://api.ugschool.edu.ge/public/api/uploadProfileFiles', formData,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
      })

       },
       getcurrentsemester(state){

          return axios.get('https://api.ugschool.edu.ge/public/api/currentSemester')


        

       },
       getyears(state){

         return axios.get('https://api.ugschool.edu.ge/public/api/getYears')

       },
       defineSemester(state,{weliID}){

           return axios.post('https://api.ugschool.edu.ge/public/api/defineSemester',{weliID})


       },
       insertclass(state,{clasess,branchID}){


        return axios.post('https://api.ugschool.edu.ge/public/api/insertClasess',{clasess,branchID})
       },
       getclasess(state,{branchID,classlist}){

        return axios.post('https://api.ugschool.edu.ge/public/api/getCurrentSemesterClasses',{branchID,classlist})
       },

       PersonSub(state,{branchID}){
        
       return axios.post('https://api.ugschool.edu.ge/public/api/personSubjects',{branchID})

       },

       saveparameters(state,{personSubjectID,hoursInWeek,summary,not_study,branchID}){

        return axios.post('https://api.ugschool.edu.ge/public/api/saveParameters',{personSubjectID,hoursInWeek,summary,not_study,branchID})
       },

       getParameters(state,{klasiID,branchID}){

       

        return axios.post('https://api.ugschool.edu.ge/public/api/getParameters',{klasiID,branchID})

       },

     Room(state,{branchID}){

      return axios.post('https://api.ugschool.edu.ge/public/api/rooms',{branchID})
     },
     saveSchedule(state,{paramID,gakvetiliN,auditoryID,dayID}){

        return axios.post('https://api.ugschool.edu.ge/public/api/saveSchedule',{paramID,gakvetiliN,auditoryID,dayID})


     },
     openschedule(state,{paramID}){

      return axios.post('https://api.ugschool.edu.ge/public/api/getSchedule',{paramID})

     },
     deleteschedule(state,{cxriliID}){

      return axios.post('https://api.ugschool.edu.ge/public/api/deleteSchedule',{cxriliID})
     },
     getsubjects(state){

      return axios.post('https://api.ugschool.edu.ge/public/api/getsubject')
     },
     deleteSubjectPerson(state,{personSubjectID}){

      return axios.post('https://api.ugschool.edu.ge/public/api/deletepersonSubject',{personSubjectID})
     },
     addPupilsToclass(state,{branchID}){

      return axios.post('https://api.ugschool.edu.ge/public/api/currentPupil',{branchID})

     },
     savePupilsToClass(state,{moswavleID,klasiID}){

         return axios.post('https://api.ugschool.edu.ge/public/api/AddStudents',{moswavleID,klasiID})
     },


     getPupilsByClass(state,{classID}){

        return axios.post('https://api.ugschool.edu.ge/public/api/getPupilsByClass',{classID})

     },
     removepupil(state,{class_pupilID}){
         return axios.post('https://api.ugschool.edu.ge/public/api/deletePupilFromClass',{class_pupilID})

     },
     addSubject(state,{subject}){

      return axios.post('https://api.ugschool.edu.ge/public/api/addSubjects',{subject})

     },
     subjectPerson(state,{saganiID,branchID}){
        return axios.post('https://api.ugschool.edu.ge/public/api/getSubjectPersons',{saganiID,branchID})

     },

      addteachers(state,{branchID}){

          return axios.post('https://api.ugschool.edu.ge/public/api/getTeachers',{branchID})

      },
      saveteacher(state,{personID,subjectID}){
          return axios.post('https://api.ugschool.edu.ge/public/api/saveteacher',{personID,subjectID})

      },
      saveform(state,form){
         return axios.post('https://api.ugschool.edu.ge/public/api/saveform',form)

      },
      getPupils(state,{classID}){

          return axios.post('https://api.ugschool.edu.ge/public/api/getPupils',{classID})
      },

      expiredcontraqts(state){
          return axios.post('https://api.ugschool.edu.ge/public/api/expiredcontraqts')


      },
      studentaccruals(state){
          return axios.post('https://api.ugschool.edu.ge/public/api/studentaccruals')
      },
      getusers(state){

          return axios.post('https://api.ugschool.edu.ge/public/api/getusers')

      },
      saveinfo(state,form){

          return axios.post('https://api.ugschool.edu.ge/public/api/saveinfo',form)
      },

      getprof(state){
          return axios.post('https://api.ugschool.edu.ge/public/api/getprof')
      },
      getmenegment(state){

          return axios.post('https://api.ugschool.edu.ge/public/api/getmenegment')


      },
      savemenegment(state,form){

          return axios.post('https://api.ugschool.edu.ge/public/api/savemenegment',form)
      },
      showinfo(state){

          return axios.post('https://api.ugschool.edu.ge/public/api/showinfo')

      },
      getcurrentperson(state,{branchID}){
          return axios.post('https://api.ugschool.edu.ge/public/api/getcurrentperson',{branchID})


      },
      getnocurrentpersons(state,{branchID}){
          return axios.post('https://api.ugschool.edu.ge/public/api/getnocurrentpersons',{branchID})


      },

    getbooks(state,{branchID}){
        return axios.post('https://api.ugschool.edu.ge/public/api/getbooks',{branchID})
    },

      searchusers(state,{keyword}){
          return axios.get('https://api.ugschool.edu.ge/public/api/searchusers?keyword=' + keyword)

      },
      getpersonditeil(state,{personID}){
          return axios.post('https://api.ugschool.edu.ge/public/api/personditeil',{personID})

      },
      savefform(state,form){

          return axios.post('https://api.ugschool.edu.ge/public/api/savefform',form)
      },
      getlanguage(state){

          return axios.post('https://api.ugschool.edu.ge/public/api/getlanguage')


      },
      getcategory(state){

          return axios.post('https://api.ugschool.edu.ge/public/api/getcategory')
      },
      chooseperson(state){

          return axios.post('https://api.ugschool.edu.ge/public/api/chooseperson')

      },
      bookss(state,{branchID}){

          return axios.post('https://api.ugschool.edu.ge/public/api/bookss',{branchID})

      },

      savebookinfo(state,bookinfo){

          return axios.post('https://api.ugschool.edu.ge/public/api/savebookinfo',bookinfo)

      },
      Bringing(state,{ID}){
          return axios.post('https://api.ugschool.edu.ge/public/api/Bringing', {ID})

      },
      deletbookinfo(state,{ID}){
          return axios.post('https://api.ugschool.edu.ge/public/api/deletbookinfo', {ID})

      },
      updateFormbooks(state,form){

          return axios.post('https://api.ugschool.edu.ge/public/api/updateFormbooks',form)
      },
      updatebookinfoo(state,{ID}){

          return axios.post('https://api.ugschool.edu.ge/public/api/updatebookinfoo', {ID})
      },
      getklassandsubject(state,{personID}){

          return axios.post('https://api.ugschool.edu.ge/public/api/getklassandsubject', {personID})
      },

      saveklassandsubject(state,homeworkform){

          return axios.post('https://api.ugschool.edu.ge/public/api/saveklassandsubject',homeworkform)

      },
      klasandsubject(state,{personID}){

          return axios.post('https://api.ugschool.edu.ge/public/api/klasandsubject',{personID})
      },
      deletsub(state,{ID}){

          return axios.post('https://api.ugschool.edu.ge/public/api/deletsub',{ID})

      },
      persontable(state,{personID}){

          return axios.post('https://api.ugschool.edu.ge/public/api/persontable',{personID})
      },
      getsubjectinfo(state,{personID}){

          return axios.post('https://api.ugschool.edu.ge/public/api/getsubjectinfo',{personID})

      },
      getmisses(state,{personID}){

          return axios.post('https://api.ugschool.edu.ge/public/api/getmisses',{personID})

      },
      getklasiid(state,{klasiID}){

          return axios.post('https://api.ugschool.edu.ge/public/api/getklasiid',{klasiID})

      },

      insertqula(state,{point,tarigi,paramID,klasi,semestriID,personID}){

          return axios.post('https://api.ugschool.edu.ge/public/api/insertqula',{point,tarigi,paramID,klasi,semestriID,personID})

      },
      gettableinfo(state,{paramID,klasi,semestriID,personID,tarigi}){

          return axios.post('https://api.ugschool.edu.ge/public/api/gettableinfo',{paramID,klasi,semestriID,personID,tarigi})
      },
      getklasi(state,{klasiID}){

          return axios.post('https://api.ugschool.edu.ge/public/api/getklasi',{klasiID})
      },
      insertmisses(state,{paramID,klasi,semestriID,personID,miss,tarigi}){

          return axios.post('https://api.ugschool.edu.ge/public/api/insertmisses',{paramID,klasi,semestriID,personID,miss,tarigi})
      },
      tablein(state,{paramID,klasi,semestriID,personID,miss,tarigi}){

          return axios.post('https://api.ugschool.edu.ge/public/api/tablein',{paramID,klasi,semestriID,personID,miss,tarigi})
      },
      updatetable(state,{gacdenaID,gacdena}){

          return axios.post('https://api.ugschool.edu.ge/public/api/updatetable',{gacdenaID,gacdena})
      },
      updatetablee(state,{agebuliID,agebuli,qula,tarigi}){

          return axios.post('https://api.ugschool.edu.ge/public/api/updatetablee',{agebuliID,agebuli,qula,tarigi})
      },
      getsubjectt(state,{personID}){
          return axios.post('https://api.ugschool.edu.ge/public/api/getsubjectt',{personID})
      },
      addtheme(state,{klasiID,saganiID,theme,personID}){
          return axios.post('https://api.ugschool.edu.ge/public/api/addtheme',{klasiID,saganiID,theme,personID})
      },
      gettheme(state,{personID,klasiID,saganiID}){
          return axios.post('https://api.ugschool.edu.ge/public/api/gettheme',{personID,klasiID,saganiID})

      },
      getScore(state){
          return axios.post('https://api.ugschool.edu.ge/public/api/getScore')

      },
      getqvestions(state){

          return axios.post('https://api.ugschool.edu.ge/public/api/getqvestions')
      },
      insertforminfo(state,formData){

          return axios.post('https://api.ugschool.edu.ge/public/api/insertforminfo',formData)
      },
      Scoreinfo(state,{themeID,personID}){

          return axios.post('https://api.ugschool.edu.ge/public/api/Scoreinfo',{themeID,personID})
      },
      getanswers(state,{questionID,personID}){

          return axios.post('https://api.ugschool.edu.ge/public/api/getanswers',{questionID,personID})

},
      Generateticket(state,{selecttheme,ticktname,klasiID,saganiID}){
          return axios.post('https://api.ugschool.edu.ge/public/api/Generateticket',{selecttheme,ticktname,klasiID,saganiID})
      },
      gettickitinfoo(state,{klasiID,saganiID}){
          return axios.post('https://api.ugschool.edu.ge/public/api/gettickitinfoo',{klasiID,saganiID})

      },
      getmessageslist(state,{code}){
          return axios.post('https://api.ugschool.edu.ge/public/api/getmessageslist',{code})
      },
      creatconverstion(state,{sender,receiver}){
          return axios.post('https://api.ugschool.edu.ge/public/api/creatconverstion',{sender,receiver})
      },
      getopenmessage(state,conversationID){
          return axios.post('https://api.ugschool.edu.ge/public/api/getopenmessage',{conversationID})
      },
      sendmessage(state,formData){
          return axios.post('https://api.ugschool.edu.ge/public/api/sendmessage',formData)
      },
      showimg(state,personID){
          return axios.post('https://api.ugschool.edu.ge/public/api/showimg',personID)
      },
      persons(state,{branchID}){
          return axios.post('https://api.ugschool.edu.ge/public/api/persons',{branchID})
      },
      logisticsRequest(state,{selecttheme,personID}){

          return axios.post('https://api.ugschool.edu.ge/public/api/logisticsRequest',{selecttheme,personID})
      },
      getrequestlogistics(state){

          return axios.post('https://api.ugschool.edu.ge/public/api/getrequestlogistics')
      },
      updatePrice(state,{price}){

          return axios.post('https://api.ugschool.edu.ge/public/api/updatePrice',{price})
      },
      selectlogisticss(state){

          return axios.post('https://api.ugschool.edu.ge/public/api/getrequestlogistics')
      },
      updateApprvee(state,{forUpdate,isChecked}){

          return axios.post('https://api.ugschool.edu.ge/public/api/updateApprvee',{forUpdate,isChecked})
      },

      selectClass(state){

          return axios.post('https://api.ugschool.edu.ge/public/api/selectClass')
      },

      saveEventcalendar(state,formData){

          return axios.post('https://api.ugschool.edu.ge/public/api/saveEventcalendar',formData)
      },
      selectCalendar(state,{personID}){

          return axios.post('https://api.ugschool.edu.ge/public/api/selectCalendar',{personID})
      },
      insertfoodmenu(state,formData){

          return axios.post('https://api.ugschool.edu.ge/public/api/insertfoodmenu',formData)

      },
      selectfoodcalendar(state,{personID}){

          return axios.post('https://api.ugschool.edu.ge/public/api/selectfoodcalendar',{personID})
      },
      selectdays(state){
          return axios.post('https://api.ugschool.edu.ge/public/api/selectdays')

      },
      selectlonglesson(state){
          return axios.post('https://api.ugschool.edu.ge/public/api/selectlonglesson')
      },
      selectteacherss(state){
          return axios.post('https://api.ugschool.edu.ge/public/api/selectteacherss')
      },
      savelonglessons(state,formData){

          return axios.post('https://api.ugschool.edu.ge/public/api/savelonglessons',formData)
      },
      selecttableinfogaxangdzlivebuli(state){
          return axios.post('https://api.ugschool.edu.ge/public/api/selecttableinfogaxangdzlivebuli')
      },
      specpersonali(state){
          return axios.post('https://api.ugschool.edu.ge/public/api/specpersonali')

      },

      updatespec({state},value){
          return axios.post('https://api.ugschool.edu.ge/public/api/updatespec',value)

      },
      specpersonalilist(state){
          return axios.post('https://api.ugschool.edu.ge/public/api/specpersonalilist')
      },
      specmoswavle(state){

          return axios.post('https://api.ugschool.edu.ge/public/api/specmoswavle')
      },
      specinfoinsert(state,formData){
          return axios.post('https://api.ugschool.edu.ge/public/api/specinfoinsert',formData)
      },
      nextmuve(state,formData){
          return axios.post('https://api.ugschool.edu.ge/public/api/nextmuve',formData)
      },
      infotableeeeeee(state,{ID}){
          return axios.post('https://api.ugschool.edu.ge/public/api/infotableeeeeee',{ID})
      },
      selectmdzgoli(state){

          return axios.post('https://api.ugschool.edu.ge/public/api/selectmdzgoli')
      },
      insertransport(state,formData){
          return axios.post('https://api.ugschool.edu.ge/public/api/insertransport',formData)
      },
      selecttransportt(state){
          return axios.post('https://api.ugschool.edu.ge/public/api/selecttransportt')
      },
      selectclasinfooo(state){
          return axios.post('https://api.ugschool.edu.ge/public/api/selectclasinfooo')
      },
      selectprofesia(state,{clasiID}){

          return axios.post('https://api.ugschool.edu.ge/public/api/selectprofesia',{clasiID})
      },
      selectstudentsss(state,{klasiID}){
          return axios.post('https://api.ugschool.edu.ge/public/api/selectstudentsss',{klasiID})

      },
      selectdamrigeblebi(state){

          return axios.post('https://api.ugschool.edu.ge/public/api/selectdamrigeblebi')
      },
      selectdamrigebeli(state,formData){
          return axios.post('https://api.ugschool.edu.ge/public/api/selectdamrigebeli',formData)
      },
      selectclasmenu(){
          return axios.post('https://api.ugschool.edu.ge/public/api/selectclasmenu')
      },
      selectmoswprofile(state,{clasiID}){
          return axios.post('https://api.ugschool.edu.ge/public/api/selectmoswprofile',{clasiID})
      },
      selectfood(state,{classID}){

          return axios.post('https://api.ugschool.edu.ge/public/api/selectfood',{classID})
      },
      selecttableforstudents(state,{classID}){
          return axios.post('https://api.ugschool.edu.ge/public/api/selecttableforstudents',{classID})
      },
      selecteventcalendarforstudents(state,{classID}){
          return axios.post('https://api.ugschool.edu.ge/public/api/selecteventcalendarforstudents',{classID})
      },
      selectpersoninfo(state,{option}){

          return axios.post('https://api.ugschool.edu.ge/public/api/selectpersoninfo',{option})
      },
      selectmoswavleclub(state){
          return axios.post('https://api.ugschool.edu.ge/public/api/selectmoswavleclub')
      },
      selectteacherclub(state){
          return axios.post('https://api.ugschool.edu.ge/public/api/selectteacherclub')
      },
      selectdgeclub(state){
          return axios.post('https://api.ugschool.edu.ge/public/api/selectdgeclub')
      },
      saveclub(state,formData){
          return axios.post('https://api.ugschool.edu.ge/public/api/saveclub',formData)
      },
      selectclub(state){
          return axios.post('https://api.ugschool.edu.ge/public/api/selectclub')
      },
      nextclubinfooo(state,{clubID}){
          return axios.post('https://api.ugschool.edu.ge/public/api/nextclubinfooo',{clubID})

      },
      selectlisofstudents(state,{clubID}){
          return axios.post('https://api.ugschool.edu.ge/public/api/selectlisofstudents',{clubID})

      },
      selectkaterdra(state){

          return axios.post('https://api.ugschool.edu.ge/public/api/selectkaterdra')
      },
      selectkaterdrapers(state,{katedraID}){
          return axios.post('https://api.ugschool.edu.ge/public/api/selectkaterdrapers',{katedraID})
      },
      selectmisskatedra(state,formData){

          return axios.post('https://api.ugschool.edu.ge/public/api/selectmisskatedra',formData)
      },
      selectgacdenebi(state){

          return axios.post('https://api.ugschool.edu.ge/public/api/selectgacdenebi')
      },
      selectgetreason(state,{personID}){
          return axios.post('https://api.ugschool.edu.ge/public/api/selectgetreason',{personID})
      },
      pesonalinfobook(state,{personID}){

          return axios.post('https://api.ugschool.edu.ge/public/api/pesonalinfobook',{personID})
      },
      updatemoswinfo(state){

          return axios.post('https://api.ugschool.edu.ge/public/api/updatemoswinfo')
      },
      editPerson(state,{contractID}){

          return axios.post('https://api.ugschool.edu.ge/public/api/editPerson',{contractID})
      },
      getcontract_type(state){

          return axios.post('https://api.ugschool.edu.ge/public/api/getcontract_type')
      },
      getstatus(state){

          return axios.post('https://api.ugschool.edu.ge/public/api/getstatus')
      },

      getcontactType(state){
          return axios.post('https://api.ugschool.edu.ge/public/api/getcontactType')
      },
      insertcontracts(state,formData){
          return axios.post('https://api.ugschool.edu.ge/public/api/insertcontracts',formData)
      }








  }

   ,
 
  
  modules: {

  }
})
