<template>



  <div class="container-fluid g-1">
    <div class="row g-0">
      <div class="col-3">
        <div class="search-box search-box-bg">
          <i class='bx bx-search-alt-2'></i>
          <Multiselect
              placeholder="ძიება"
              :filter-results="false"
              :min-chars="3"
              :resolve-on-load="false"
              :delay="0"
              :searchable="true"
              @select="goToUserProfile"
              :options="loadUsers" />

        </div>


        <div class="recipient-box">
          <div @click="openmessage(inf.conversationID,inf.saxeli,inf.gvari,inf.code)" class="recipient" v-for="(inf,i) in messageslist " :key="i">
            <div class="row">
              <div class="col-2">
                <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" class="rounded-circle" alt="">

              </div>
              <div class="col-10 pt-3">
                <p >{{inf.saxeli}} {{inf.gvari}}({{inf.code}})</p>

              </div>

            </div>

          </div>

          <button class="btn btn-info d-block mx-auto my-2" v-if="messageslist && messageslist.length==1 && this.isFiltered" @click="reloadMessages()">ფილტრის მოხსნა</button>

        </div>

      </div>


      <div class="col-9" v-show="hidden">
        <div class="active-recipient">
          <div class="row ">
            <div class="col-1">
              <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" class="rounded-circle" alt="">

            </div>
            <div class="col-11 pt-3">
              <p>{{name}}</p>

            </div>

          </div>

        </div>

        <div class="chat-box" v-show="hidden" id="messageBody">



          <div style="margin-right: 20px;margin-top: 10px; background-color: #eeeeee; word-wrap: break-word;"  v-for="(mess,i) in openmessages " :key="i" :class="mess.sender==this.$store.state.user.code?'chat chat-right':'chat chat-left'">

            <span v-html="mess.messages"></span>




            <a target="_blank" v-if="mess.files"  :href="'http://localhost/onlineugschoolAPI/'+mess.files">მიმაგრებული ფაილი</a>





          </div>




        </div>
        <div class="message-box">
          <div class="row">
            <div class="col-9">
              <editor v-on:keydown.enter="sendmessage"  ref="editor"  v-model="savemessage" placeholder="შეტყობინება ჩაწარეთ აქ"/>
            </div>
            <div class="col-3 ">
              <div class="row h-100 justify-content-center align-items-center">

                <div class="col-11">
                  <i class='bx bx-paperclip'  @click="openchoosefile" ></i>
                  <input type="file" id="my_file" @change="getfile"  class="d-none">
                  <i class='bx bx-send' @click="sendmessage"></i>
                </div>

              </div>

            </div>
          </div>


        </div>

      </div>
    </div>

  </div>


















</template>
<script>
import Multiselect from "@vueform/multiselect";
import Editor from '@tinymce/tinymce-vue'


export default {
  components: { Multiselect,editor:Editor },
  data(){
    return{
      formData: new FormData(),
        hidden: false,
        messageslist: null,
        userList: null,
        openmessages: null,
        name: null,
        savemessage: null,
        codee: null,
        conversationid: null,
      filess:null,
      saxeli:null,
      gvari:null,
      scrollHeight:null,
      isFiltered:false,
      messagebox:null
    }
  },mounted() {
   this.messageList()

  },methods: {
    messageList(){

      this.$store.dispatch('getmessageslist',{code:this.$store.state.user.code}).then(response=>{


        this.messageslist=response.data

      })

    },

    reloadMessages(){

        location.reload()

    },
    goToUserProfile(option) {
      const users = this.userList.find(o => parseInt(o.value) === parseInt(option))
      this.$store.dispatch('creatconverstion',{sender:this.$store.state.user.code,receiver:users.code}).then(response=>{

            if(response.data){


                this.messageslist=this.messageslist.filter(obj=>{


                      return parseInt(obj.conversationID)===response.data



                })

              this.isFiltered=true

            }else {

                this.messageList()


            }



      })






    },
    async loadUsers(query) {
      const response = await this.$store.dispatch('searchusers', { keyword: query })

      this.userList = await response.data


      return this.userList

    },

    openmessage(conversationID,saxeli,gvari,kodi){

      this.hidden=true


      this.name=saxeli+' '+gvari+' ('+kodi+')'
      this.codee=kodi
      this.saxeli=saxeli
      this.gvari=gvari
      this.conversationid=conversationID

      this.$store.dispatch('getopenmessage',{conversationID:conversationID}).then(response=>{
        this.openmessages = response.data
      // this.messagebox = document.getElementById('messageBody')

        setTimeout(() => {
          document.getElementById('messageBody').scrollTop=1000000
        }, 20);
      })


    },


    sendmessage(event){
      if(this.savemessage==null&&this.filess==null){
        this.$helper.notifyErrorMessage('დაწერეთ მესიჯი ან ატვირთეთ ფაილი')
      }else {
        this.formData.append('sender', this.$store.state.user.code)
        this.formData.append('receiver', this.codee)
        this.formData.append('conver', this.conversationid)
        this.formData.append('savemes',  this.savemessage)



        this.$store.dispatch('sendmessage', this.formData).then(response => {
          if (event) event.target.innerHTML = ''
          this.openmessage(this.conversationid,this.saxeli,this.gvari,this.codee)
          this.savemessage=null

          this.filess=null
          this.formData= new FormData()

        })




      }
    },
    getfile(event){
this.filess=event.target.files[0]
      this.formData.append('img',event.target.files[0])

    },

    openchoosefile(){
      document.getElementById('my_file').click()

    }

  }
}

</script>