<template>
  <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col">მოსწავლე</th>
      <th scope="col">პირადი:N</th>
      <th scope="col">თანხა</th>
      <th scope="col">კოდი</th>
      <th scope="col">კლასი</th>
      <th scope="col">კონტრაქტის დაწყება</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(accruals,i) in Studentaccruals" :key="i">
      <td>{{accruals.mosw}}</td>
      <td>{{accruals.piradi_num}}</td>
      <td>{{accruals.fee}}</td>
      <td>{{accruals.kodi}}</td>
      <td>{{accruals.klasi}}</td>
      <td>{{accruals.contr_start}}</td>
    </tr>

    </tbody>
  </table>

</template>
<script>
export default {
  data(){
    return{
      Studentaccruals:null,

    }

  },mounted() {
    this.$store.dispatch('studentaccruals').then(response=>{

      this.Studentaccruals=response.data
    })
  }

}
</script>