<script>


    export default{

            mounted(){

                    this.$store.dispatch('logout')

            }


    }



</script>
