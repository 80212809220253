<template>
  {{getcurrent}}
  <div class="modal fade" id="edit" tabindex="-1" role="dialog" aria-labelledby="edit" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form  v-for="(pers,i) in personinfo" :key="i">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="name">სახელი</label>
                <input type="text"  class="form-control" id="name" v-model="form.name">
              </div>

              <div class="form-group col-md-6">
                <label for="gvari">გვარი</label>
                <input type="text"  class="form-control" id="gvari" v-model="form.gvari">
              </div>
              <div class="form-group col-md-12">
                <label for="email">ელფოსტა</label>
                <input type="email" class="form-control" id="email" v-model="form.email" >
              </div>
            </div>
            <div class="form-group">
              <label for="addres">მისამართი</label>
              <input type="text" class="form-control" id="addres" v-model="form.addres">
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="strar_contract">კონტრაქტის დაწყება</label>
                <input type="date" class="form-control" id="strar_contract" v-model="form.start_contract">
              </div>
              <div class="form-group col-md-6">
                <label for="end_contract">კონტრაქტის დასრულება</label>
                <input type="date" class="form-control" id="end_contract" v-model="form.end_contract">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="salary">ხელფასი</label>
                <input type="number" class="form-control" id="salary" v-model="form.salary">
              </div>
              <div class="form-group col-md-6">
                <label >კონტრაქტის ტიპი</label>
                <Multiselect
                    v-model="form.value"
                    :options="getcontactType"
                    :searchable="true"
                />
              </div>

            </div>
            <div class="form-group">
              <label for="number">მობილური</label>
              <input type="text" class="form-control" id="number" v-model="form.number">
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light w-100" @click="contractsupdate()">რედაქტირება</button>
        </div>
      </div>
    </div>
  </div>
  <table class="table table-striped table-responsive" style="font-size: 12px;">
    <thead>
    <tr>
      <th scope="col">პერსონალი</th>
      <th scope="col">მისამართი</th>
      <th scope="col">მობილური</th>
      <th scope="col">ელფოსტა</th>
      <th scope="col">კონტრაქტის დაწყება</th>
      <th scope="col">კონტაქტის დასრულება</th>
      <th scope="col">შეწყვეტის თარიღი</th>
      <th scope="col">მიზეზი</th>
      <th scope="col">კონტაქტის ნომერი</th>
      <th scope="col">ხელფასი</th>
      <th scope="col">კონტაქტის ტიპი</th>

    </tr>
    </thead>
    <tbody>
    <tr v-for="(currentpersoninfo,i) in getcurrent" :key="i">
      <th scope="row">{{currentpersoninfo.pers}}</th>
      <td>{{currentpersoninfo.misamarTi}}</td>
      <td>{{currentpersoninfo.mobiluri}}</td>
      <td>{{ currentpersoninfo.elfosta }}</td>
      <td>{{currentpersoninfo.contract_start_date}}</td>
      <td>{{ currentpersoninfo.contract_end_date }}</td>
      <td>{{currentpersoninfo.shewyvetis_tar}}</td>
      <td>{{currentpersoninfo.mizezi}}</td>
      <td>{{currentpersoninfo.contract_number}}</td>
      <td>{{currentpersoninfo.salary}}</td>
      <td>{{currentpersoninfo.contract_type}}</td>
      <td><button @click="editPersoncontracts(currentpersoninfo.contractID)" type="button" class="btn btn-light" data-toggle="modal" data-target="#edit">რედაქტირება</button></td>
    </tr>
    </tbody>
  </table>

</template>

<script>
export default {
  data(){

    return{
      formData: new FormData(),
      form:{
        name:null,
        gvari:null,
        email:null,
        addres:null,
        start_contract:null,
        end_contract:null,
        salary:null,
        value:null,
        number:null,
      },
      getcurrent:null,

    }
  },mounted() {
    this.$store.dispatch('getnocurrentpersons',{branchID:this.$store.state.user.branchID}).then(response=>{

      this.getcurrent=response.data

    })



  },methods:{
    editPersoncontracts(contractID){

      console.log(contractID)
    }
  }

}

</script>