<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
      <form class="mt-5">
        <div class="form-row">
          <div class="col-7">
            <input type="text" class="form-control" placeholder="ღონისძიება" v-model="form.Event">
          </div>
          <div class="col">

              <Multiselect
              v-model="valuess"
              :options="Class"
              mode="tags"
              :close-on-select="false"
              :searchable="true"
          />

          </div>
          <div class="col">
            <input type="date" class="form-control" placeholder="თარიღი" v-model="form.Datee">
          </div>
        </div>
        <button type="button" class="btn btn-secondary btn-block mt-5" @click="saveEventcalendar">შენახვა</button>
      </form>



    </div>

    </div>


  </div>

  <v-calendar
      class="custom-calendar max-w-full"
      :masks="masks"
      :attributes="attributes"
      disable-page-swipe
      is-expanded
  >
    <template v-slot:day-content="{ day, attributes }">
      <div class="flex flex-col h-full z-10 overflow-hidden">
        <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
        <div class="flex-grow overflow-y-auto overflow-x-auto">
          <p
              v-for="attr in attributes"
              :key="attr.key"
              class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
              :class="attr.customData.class"
          >
            {{ attr.customData.title }}
          </p>
        </div>
      </div>
    </template>
  </v-calendar>


</template>

<script>
import Multiselect from "@vueform/multiselect";
import Editor from "@tinymce/tinymce-vue";
export default {
  components: { Multiselect,editor:Editor },
  data(){
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    return{
      formData: new FormData(),
      form: {
        selectClass:0,
        Event:null,
        Datee:null
      },
      Calendar:null,
      valuess:null,

      Class:null,
      masks: {
        weekdays: 'WWW',
      },
      attributes: [],




    }

  },mounted() {

    this.$store.dispatch('selectClass').then(response=>{

       this.Class=response.data


    })

    this.$store.dispatch('selectCalendar' ,{personID:this.$store.state.user.personaliID}).then(response=>{



        for(let data of response.data){



          this.attributes.push({key:data.eventID,dates:data.tarigi,customData:{title:data.Description+', '+data.klasi+' კლასი',class:'bg-danger text-white'} })

        }



    })


  },methods:{
    saveEventcalendar(){


      this.formData.append('personID', this.$store.state.user.personaliID)
      this.formData.append('klasiID',this.valuess)
      this.formData.append('selectClass',this.selectClass)
      this.formData.append('Datee', this.form.Datee)
      this.formData.append('Event', this.form.Event)

      this.$store.dispatch('saveEventcalendar',this.formData).then(response=>{

        if(response.data.status=='OK'){
          this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით  შეინახა')

        }else {
          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!')

        }

      })


    }


  }



}

</script>
<style lang="scss" scoped>




::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
  .vc-container {

  border-radius: 0;
  width: 100%;
 .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
 .vc-weeks {
    padding: 0;
  }
 .vc-weekday {
    background-color: #f8fafc;
    border-bottom: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea;
    padding: 5px 0;
  }
 .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: 90px;
    min-width: 90px;
    background-color: white;
.weekday-1,
.weekday-7 {
   background-color: #eff8ff;
 }
&:not(.on-bottom) {
   border-bottom: 1px solid #b8c2cc;
.weekday-1 {
   border-bottom: 1px solid #b8c2cc;
 }
}
&:not(.on-right) {
   border-right: 1px solid #b8c2cc;
 }
}
 .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>