<template>
   
<chu-tagz :placeHolder="tagLabel" :setInfo="insertClasess"  />

<br><br> 
 

     
<Accordion @tab-open="openAccordion($event)"> 

    
    
	<AccordionTab :header="clasess.klasi" v-for="(clasess,index) in classlist" :key="index">
            
             <div class="row">

            <div class="col-12">

                <p class="text-center font-weight-bold"> პარამეტრები {{clasess.klasi}} კლასისთვის</p> 
                <p class="text-right"><button class="btn btn-warning" @click="addPupilsToclass(clasess.klasiID)">მოსწავლეების დამატება</button></p>
                <form>  
                    <div class="form-group">
                        <label>მასწავლებელი(საგანი)</label>
                        <select class="form-control" v-model="personSubjectID">

                            <option :value="personSubject.personID+'_'+personSubject.subjectID+'_'+clasess.klasiID" v-for="(personSubject,index) in Psubjects" :key="index">{{personSubject.subjectPers}}</option> 

                        </select> 
                    </div>

                    <div class="form-group">

                        <div class="row">
                            <div class="col-4">
                                 <label> კვირაში სთ. რაოდენობა </label>   
                                <input type="number" v-model="hoursInWeek" class="form-control"> 
                            </div>
                            <div class="col-4">
                                 <label>შემაჯამებელი</label>   
                                <input type="number" v-model="summary" class="form-control">

                            </div>
                            <div class="col-4">
                                <label>გამოჩნდეს რეპორტში?</label><br>
                                <input type="radio" value="0" name="not_study" v-model="not_study"   checked>-კი<br>
                                <input type="radio" value="1" name="not_study" v-model="not_study"  >-არა


                            </div>
                        </div>
                            
                    </div>
                    <button type="button" class="btn btn-block btn-success" @click="saveParameters">შენახვა</button>

                </form>

                            <hr>


                    <Accordion @tab-open="openInnerAccordion($event)">
                        <AccordionTab :header="params.saxeli+' '+params.gvari+' ('+params.sagani+') | კვირაში სთ.რაოდ: '+params.kvira_st_raod+' | შემაჯამებლების რაოდ.: '+params.shemajamebeli+' | ჩანს რეპორტში: '+params.not_study"   v-for="(params,ind) in parameters" :key="ind" >
                                
                                      
                                    <form>
                                        <div class="row">
                                            <div class="col-12 col-md-4">
                                                <div class="form-group">
                                                    <input type="number"  class="form-control" v-model="gakvetiliN" placeholder="გაკვეთილის N">
                                                </div>
                                            </div>
                                             <div class="col-12 col-md-4">
                                                 <div class="form-group">
                                                    <select class="form-control" v-model="auditoryID">

                                                        <option v-for="(room,ind) in rooms " :key="ind" :value="room.ID">{{room.auditoria}}</option>

                                                    </select>
                                                </div>
                                             </div>

                                            <div class="col-12 col-md-4">


                                                  <Multiselect
                                                v-model="dayID"
                                                :options="days"
                                                mode="tags"
                                                :close-on-select="false"
                                                :searchable="true"
                                                />




                                                 
                                                    


                                            </div>


                                        </div>

                                            

                                    </form> 
                                  

                                                       

                                                 
                                                 <div class="row " v-for="(sched,i) in schedule" :key="i">
                                                     <div class="col-2 " >გაკვეთილის N: <span class="font-weight-bold"> {{sched.gakvetilisN}} </span></div>
                                                     <div class="col-5" >აუდიტორია: <span class="font-weight-bold">  {{sched.auditoria}}</span></div>
                                                     <div class="col-4" >დღე: <span class="font-weight-bold">  {{sched.dge}}</span></div>
                                                     <div class="col-1"> <span class="font-weight-bold" style="cursor:pointer" @click="deleteSchedule(sched.cxriliID,i)"> X</span></div>
                                                 </div>

                                                 <hr>
                                            


                             

                                     <button class="btn btn-success btn-block" type="button" @click="saveSchedule(params.ID)"> შენახვა </button>

                        </AccordionTab>

                    </Accordion>



               


               
               
            
 

            </div>
              

        </div>


      

	</AccordionTab>


 
</Accordion>

 

     
 
  
     <modal :isOpen="ishown" id="id"  @close='ishown=false' >

            <template v-slot:body> 

                   <Multiselect
                    v-model="value"
                    :options="options"
                    mode="tags"
                    :close-on-select="false"
                    :searchable="true"
                    />
                   <button class="btn btn-success btn-block mt-2" @click="savePupilsToClass()">შენახვა</button> 


                   <div class="row">
                        <div class="col-12 border-bottom mt-3"  v-for="(pupil, index) in pupilsbyclass" :key="index" > 

                                <div class="row">
                                    <div class="col-10">
                                            {{pupil.mosw}}
                                        </div>
                                    <div class="col-2">
                                            <i style="cursor:pointer" class='bx bx-message-square-x' @click="removepupil(index,pupil.class_pupilID)"></i>
                                       </div>

                                </div>
                      
                        </div>

                   </div>


            </template>

        </modal>




</template>
<script>
import chuTagz from '@/components/ChuTagz.vue'  
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import modal from '@/components/modal.vue'
import Multiselect from '@vueform/multiselect'
 
 
 
export default{
    data(){
        return{
            ishown:false,
            tagLabel:'კლასების დამატება',
            classlist:'',
            personSubjectID:'',
            hoursInWeek:'',
            summary:'',
            not_study:0,
            Psubjects:'',
            parameters:'',
            days:['ორშაბათი','სამშაბათი','ოთხშაბათი','ხუთშაბათი','პარასკევი'],
            gakvetiliN:'',
            auditoryID:'',
            dayID:[],
            rooms:'' ,
            schedule:'',
            deletesched:'',
            value: null,
            options:null,
            klasiIDForaddPupils:null,
            pupilsbyclass:'' 
            



        }
    } 

    ,

    components:{

              chuTagz ,Accordion ,AccordionTab,modal,Multiselect
             },
    methods:{
        openAccordion(event) {
             
            const classItem = this.classlist[event.index]

             this.openParams(classItem.klasiID)
        },
        openInnerAccordion(event){
            const parametersItem = this.parameters[event.index]

             this.openschedule(parametersItem.ID)

        },


            insertClasess(clasess){

                   this.$store.dispatch('insertclass',{clasess:clasess,branchID:this.$store.state.user.branchID}).then(response=>{ 

                          if(response.data.status=='OK'){

                            this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით დაემატა')



                    }else {
                            this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!')

                    }

                   })


            },
            saveParameters(){

                        this.$store.dispatch('saveparameters',{personSubjectID:this.personSubjectID,hoursInWeek:this.hoursInWeek,summary:this.summary,not_study:this.not_study,
                        
                        branchID:this.$store.state.user.branchID}).then(response=>{ 
                             
                                 if(response.data.status=='OK'){

                                        this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით დაემატა')  
                                        
                                        

                                }else { 
                                        this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!') 

                                } 

                        })
                        



            },

            openParams(klasiID){

               

                   this.$store.dispatch('getParameters',{klasiID:klasiID,branchID:this.$store.state.user.branchID}).then(response=>{

                        this.parameters=response.data


                   })

            },
            openschedule(paramID){

            

                        this.schedule=[]

                       this.$store.dispatch('openschedule',{paramID:paramID}).then(response=>{

                           this.schedule=response.data

                            

                       })

            },
            saveSchedule(paramID){


                this.$store.dispatch('saveSchedule',{paramID:paramID, gakvetiliN:this.gakvetiliN,auditoryID:this.auditoryID,dayID:this.dayID}).then(response=>{

                            if(response.data.status=='OK'){

                                     this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით დაემატა')

                                }else {
                                        this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!')

                                }



                })

                  


            },
            deleteSchedule(cxriliID,i){ 

                    this.schedule.splice(i,1)

                       this.$store.dispatch('deleteschedule',{cxriliID:cxriliID}).then(response=>{

                          if(response.data.status=='OK'){

                                     this.$helper.notifySuccessMessageWithoutRefresh('ჩანაწერი წარმატებით  წაიშალა')   

                                }else { 
                                        this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!') 

                                } 


                       })

            },
            addPupilsToclass(klasiID){ 
                         this.value=null
                
                      this.$store.dispatch('addPupilsToclass',{branchID:this.$store.state.user.branchID}).then(response=>{ 
                            this.options=response.data 
                     
                }) 

                this.$store.dispatch('getPupilsByClass',{classID:klasiID}).then(response=>{
                    this.pupilsbyclass=response.data
                })


                this.klasiIDForaddPupils=klasiID

                 this.ishown=!this.ishown 
            },

            savePupilsToClass(){
                        this.$store.dispatch('savePupilsToClass',{moswavleID:this.value, klasiID:this.klasiIDForaddPupils}).then(response=>{
                             
                               if(response.data.status=='OK'){

                                     this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით დაემატა')   

                                }else { 
                                        this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!') 

                                } 

                        

                        })




                

            },
            removepupil(index,class_pupilID){

                    this.pupilsbyclass.splice(index,1)

                this.$store.dispatch('removepupil',{class_pupilID:class_pupilID}).then(response=>{

                      if(response.data.status=='OK'){

                                     this.$helper.notifySuccessMessageWithoutRefresh('ჩანაწერი წარმატებით  წაიშალა')   

                                }else { 
                                        this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!') 

                                } 

                })

            }
             


             },
             
             mounted(){

                this.$store.dispatch('getclasess',{branchID:this.$store.state.user.branchID}).then(response=>{
                        this.classlist=response.data

                }),

                this.$store.dispatch('PersonSub',{branchID:this.$store.state.user.branchID}).then(response=>{ 
                           
                            this.Psubjects=response.data
                }),
                this.$store.dispatch('Room',{branchID:this.$store.state.user.branchID}).then(response=>{

                    this.rooms=response.data
                })
                
                



             }

}

</script>
