<template>

  <router-view/>
</template>

 <script>
 
 import axios from 'axios'
 
    export default{

        mounted(){ 

               axios.defaults.withCredentials = true;
                if(localStorage.getItem('token')){
                    axios.defaults.headers.common.Authorization ='Bearer '+localStorage.getItem('token')  
                    this.$store.dispatch('getinfoOnRefresh') 
                }

        }



    }


 </script>
 
 