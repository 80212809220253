  <template>

    <form>
        <div class="row justify-content-center">
            <div class="col-6 mt-2">
              <input type="date" class="form-control"  v-model="tarigi" id="tarigi">
            </div>
        </div>
          <div class="row border-bottom" v-for="(mosw,i) in moswavle " :key="i">
            <div class="col-2 mt-3">
              <p>{{mosw.saxeli}} {{mosw.gvari}}</p>
            </div>
              <div class="col-2">
            <div class="form-check mt-3">
              <input class="form-check-input  " type="checkbox"  v-model="miss[mosw.moswavleID]" aria-label="..." >
            </div>
              </div>
          </div>
      <button class="btn btn-secondary btn-block" @click="isertmiss()">შენახვა</button>
    </form>

    <table class="table table-striped mt-2">
      <thead>
      <tr>
        <th scope="col">სახელი</th>
        <th scope="col">გვარი</th>
        <th scope="col">კოდი</th>
        <th scope="col">თარიღი</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(table,i) in tablemosw " :key="i">


        <th scope="row">{{table.saxeli}}</th>
        <td>{{table.gvari}}</td>
        <td>{{table.kodi}}</td>
        <td><input type="date"  v-model="gacdena[table.gacdenaID]"></td>
        <td>
          <button class="btn btn-secondary btn-block" @click="updatetable(table.gacdenaID)">რედაქტირება</button></td>
      </tr>

      </tbody>
    </table>



  </template>
  <script>
  export default {
    data(){
      return{
        moswavle:null,
        tarigi:null,
        tablemosw:null,
        miss: {},
        gacdena:{}


      }

    },mounted(){

      this.$store.dispatch('getklasi',{klasiID:this.$route.params.klasiID}).then(response=>{

       this.moswavle=response.data
        for(const moswavle of this.moswavle) {
          this.miss[moswavle.moswavleID] = null
        }


      })
      this.$store.dispatch('tablein',{miss:this.moswavleID,
        personID: this.$store.state.user.personaliID,
        paramID: this.$route.params.paramID,
        klasi: this.$route.params.klasi,
        semestriID: this.$route.params.semestriID,
        tarigi:this.tarigi}).then(response=>{

        this.tablemosw=response.data

        for(const tablemosws of this.tablemosw) {
          this.gacdena[tablemosws.gacdenaID] = tablemosws.tarigi
        }

      })


    },methods:{

      isertmiss(){
        if(this.tarigi==null){
          document.getElementById('tarigi').style.border='1px solid red'

        }else {


          this.$store.dispatch('insertmisses', {
            miss:this.miss,
            personID: this.$store.state.user.personaliID,
            paramID: this.$route.params.paramID,
            klasi: this.$route.params.klasi,
            semestriID: this.$route.params.semestriID,
            tarigi:this.tarigi
          })
              .then(response => {

                if (response.data.status == 'OK') {

                  this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით დაემატა')

                } else {
                  this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!')

                }



              })

        }
      },
      updatetable(gacdenaID){

        this.$store.dispatch('updatetable',{gacdenaID:gacdenaID,gacdena:this.gacdena}).then(response=>{

          if (response.data.status == 'OK') {

            this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით განახლდა')

          } else {
            this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!')

          }


        })
      }

    }

  }
  </script>