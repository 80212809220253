<template>
    
<div class="row mt-3 ">
   <div class="col-12 text-center font-weight-bold">

       {{this.weli}}   {{this.semestri}}

      </div>

</div>

<div class="row align-items-center">
    <div class="col-12 col-lg-6 mb-4">
        
  <p class="mt-3 text-center"> ახალი აკადემიური წელი </p>
 
   <form>
     <select v-model="weliID" class="form-control">
         <option :value="year.weliID" v-for="(year, index) in years" :key="index">{{year.weli}}</option>  
     </select>

    <button type="button" class="btn btn-block btn-danger mt-2" @click="difineFirstsemester()" :disabled="isDisabled"> განსაზღვრა </button>
   </form>
     

    </div>
    <div class=" col-12 col-lg-6 mb-4 mt-5">
         <button class="btn btn-block btn-warning" @click="difineSecondsemester()"> მიმდინარე აკადემიური წლის მე-2 სემესტრი </button>

    </div>
</div>


</template>
<script>
export default{
  data(){
  return{
       
    weli:'',
    semestri:'',
     years:'',
    weliID:'' 
   

  } 

  },
  mounted(){
      
        this.$store.dispatch('getcurrentsemester').then(response=>{
       this.weli=response.data.weli
       this.semestri=response.data.semestrebi

        })

        this.$store.dispatch('getyears').then(response=>{
    
            this.years=response.data

        

        })

  },
    computed:{

            isDisabled(){

                if(this.weliID.length>0){
                    return false

                }else{

                    return true

                }

            }

    }
  ,
  methods:{
      difineFirstsemester(){
           
              this.$store.dispatch('defineSemester',{weliID:this.weliID}).then(response=>{

                    this.$helper.notifySuccessMessage(response.data.status)

              })


      },
      difineSecondsemester(){

         this.$store.dispatch('defineSemester',{weliID:0}).then(response=>{

                   this.$helper.notifySuccessMessage(response.data.status)

              })   

      }

  }
  

}
</script>
