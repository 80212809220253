<template>
<div class="container">

  <select class="form-control w-50 text-center" v-model="selectedsubject">
    <option value="0">გთხოვთ აირჩიოთ საგანი</option>
    <option :value="subj.subjectID"  v-for="(subj,i) in subject " :key="i">{{subj.sagani}}</option>
  </select>
  <div class="row" v-for="(klas,i) in classs " :key="i">

<div class="col-12 mt-2 " @click="getklassinfo(i)" style="cursor: pointer">{{klas}}</div>


  </div>




</div>





</template>
<script>
export default {
data(){
return{
  classs: {1:'1 კლასი',2:'2 კლასი',3:'3 კლასი',4:'4 კლასი',5:'5 კლასი',6:'6 კლასი',7:'7 კლასი',8:'8 კლასი',9:'9 კლასი',10:'10 კლასი',11:'11 კლასი',12:'12 კლასი'},
subject:null,
  selectedsubject:0,


}

},mounted() {
    this.$store.dispatch('getsubjectt',{personID:this.$store.state.user.personaliID}).then(response=>{


      this.subject=response.data




    })

  },methods:{

    getklassinfo(klas){
      console.log(this.selectedsubject)
      if(this.selectedsubject==0){

        alert("გთხოვთ აირჩიოთ საგანი")
      }else {

        this.$router.push('/klasssi/'+klas+'/'+this.selectedsubject)

      }
    }


  }

}
</script>