<template>

  <div class="container">
      <div class="row  mt-2">
         <div class="col-md-6 mt-2 col-lg-4">
             <div class="card text-center">
                <div class="card-body" v-for="(miss,i) in misses" :key="i">
                     <router-link :to="/teilnahme/+miss.klasiID+'/'+miss.paramID+'/'+miss.klasi+'/'+miss.semestriID" class="text-decoration-none">{{miss.sagani}} {{miss.klasi}}</router-link>
                </div>
             </div>
         </div>

      </div>

  </div>









</template>
<script>
export default {
  data(){
    return{

      misses:null,

    }


  },mounted() {
    this.$store.dispatch('getmisses',{personID:this.$store.state.user.personaliID}).then(response=>{
      this.misses=response.data

    })

  }

}


</script>
