import Swal from 'sweetalert2'
import router from './router'

export default{

        swal(){

            return Swal.mixin({ 
                customClass: {
                    closeButton: 'dialog-close',
                    cancelButton: 'dialog-cancel',
                    confirmButton: 'dialog-confirm'
                  }


            })


        },
        notifyMessage (html,
            title = '',
            icon = '',
            showConfirmButton = false,
            confirmButtonText = '',
            showCancelButton = false,
            cancelButtonText = '') {
            this.swal().fire({
              title,
              html,
              icon,
              showConfirmButton,
              showCancelButton,
              confirmButtonText,
              cancelButtonText,
              timer:'2000'
            })
          },
          
          notifySuccessMessage (message) {
            this.notifyMessage(message, '', 'success')
            setTimeout(()=>{router.go()},2000)
          },
          notifyErrorMessage (message) {
            this.notifyMessage(message, '', 'error')
           
          },
          notifySuccessMessageWithoutRefresh (message) {
            this.notifyMessage(message, '', 'success')
           
          },
          
          


}