<template>
  <div class="container">

         <form action="">
           <div class="row">

            <div class="col-sm-12  col-md-6 col-lg-3">
             <div class="form-group mt-2">
               <input type="text" class="form-control" placeholder="სახელი" v-model="form.fname" id="fname">
             </div>
            </div>
             <div class="col-sm-12  col-md-6 col-lg-3">
               <div class="form-group mt-2">
                 <input type="text" class="form-control" placeholder="გვარი" v-model="form.lname" id="lname">
               </div>
             </div>
             <div class="col-sm-12  col-md-6 col-lg-3">
               <div class="form-group mt-2">
                 <input type="number" class="form-control" placeholder="მობილური" v-model="form.phone" id="phone">
               </div>
             </div>
             <div class="col-sm-12  col-md-6 col-lg-3">
               <div class="form-group mt-2">
                 <input type="text" class="form-control" placeholder="მისამართი" v-model="form.adress" id="adress">
               </div>
             </div>
             <div class="col-sm-12  col-md-6 col-lg-3">
               <div class="form-group mt-2">
                 <input type="email" class="form-control" placeholder="ელ-ფოსტა" v-model="form.email" id="email">
               </div>
             </div>
             <div class="col-sm-12  col-md-6 col-lg-3">
               <div class="form-group mt-2">
                 <input type="text" class="form-control" placeholder="პირადობა" v-model="form.piradoba" id="piradoba" >
               </div>
             </div>
             <div class="  col-sm-12  col-md-6 col-lg-3">
               <div class="form-group mt-2">
                 <input type="text" class="form-control" placeholder="პირადობის სერია" v-model="form.piradobisS" id="piradobisS">
               </div>
             </div>

             <div class="col-sm-12  col-md-6 col-lg-3">
               <div class="form-group mt-2">
                 <input type="date" class="form-control" placeholder="დაბადების თარიღი" v-model="form.dabadebistarigi" id="dabadebistarigi">
               </div>
             </div>
             <div class="col-sm-12 col-md-4">
             <div class="form-check mt-3 text-center">
               <input class="form-check-input" type="radio" name="gender" value="1" checked v-model="form.gender" >
               <label class="form-check-label"> მამრობითი</label>

               <input class="form-check-input ml-4" type="radio" name="gender" value="0" v-model="form.gender">
               <label class="form-check-label ml-5 "> მდედრობითი</label>

             </div>
               </div>


             <div class="col-sm-12 col-md-8 ">
             <div class="form-group">
               <select class="form-control" v-model="form.userlebelID" id="userlebelID">
                 <option value="0" selected>აირჩიეთ როლი</option>
                 <option v-for="(userlevel,i) in userlevels" :key="i" :value="userlevel.userlevelID">{{userlevel.userlevel}}</option>

               </select>


             </div>

                </div>

             <div class=" col-sm12  col-md-3">
               <div class="form-group">
                 <input type="file"  @change="getimg">

               </div>

             </div>
             <div class="col-sm12 col-md-9">
               <select class="form-control" v-model="form.profesiaID" id="profesiaID">
                 <option value="0" selected>აირჩიეთ პროფესია</option>
                 <option v-for="(proff,i) in profesia" :key="i" :value="proff.profesiaID">{{proff.profesia}}</option>

               </select>


             </div>

             <div class="col-sm-12  col-md-6 col-lg-3">
               <div class="form-group mt-2">
                 <label >კონტრაქტის ნომერი</label>
                 <input type="text" class="form-control" placeholder="" v-model="form.contract_number" id="contract_number">
               </div>
             </div>
             <div class="col-sm-12  col-md-6 col-lg-3">
               <div class="form-group mt-2">
                 <label >კონტრაქტის თარიღი</label>
                 <input type="date" class="form-control"  v-model="form.contract_date" id="contract_date">
               </div>
             </div>
             <div class="col-sm-12  col-md-6 col-lg-3">
               <div class="form-group mt-2">
                 <label >კონტრაქტის დაწყების თარიღი</label>
                 <input type="date" class="form-control"  v-model="form.contract_start_date" id="contract_start_date">
               </div>
             </div>
             <div class="col-sm-12  col-md-6 col-lg-3">
               <div class="form-group mt-2">
                 <label >კონტრაქტის დას. თარიღი</label>
                 <input type="date" class="form-control"  v-model="form.contract_end_date" id="contract_end_date">
               </div>
             </div>
             <div class="col-sm-12  col-md-6 col-lg-3">
               <div class="form-group mt-2">
                 <label >შეწყვეტის თარიღი</label>
                 <input type="date" class="form-control" v-model="form.shewyvetis_tar" id="shewyvetis_tar">
               </div>
             </div>
             <div class="col-sm-12  col-md-6 col-lg-3">
               <div class="form-group mt-2">
                 <label >თანხა</label>
                 <input type="text" class="form-control" v-model="form.salary" id="salary">
               </div>
             </div>
             <div class="col-sm-12  col-md-6 col-lg-3">
               <div class="form-group mt-2">
                 <label>კონტრაქტის ტიპი</label>
                 <Multiselect id="contract_typeID"
                     v-model="form.contract_typeID"
                     :options="contract_type"
                     :searchable="true"
                 />
               </div>
             </div>
             <div class="col-sm-12  col-md-6 col-lg-3">
               <div class="form-group mt-2">
                 <label>სტატუსი</label>
                 <Multiselect id="statusID"
                     v-model="form.statusID"
                     :options="status"
                     :searchable="true"
                 />
               </div>
             </div>
             <div class="col-sm-12  col-md-6 col-lg-6">
               <div class="form-group mt-2">
                 <label>დადების თარიღი</label>
                 <input type="date" class="form-control"  v-model="form.putdate" id="putdate">
               </div>
             </div>


           </div>
           <button type="button" class="btn btn-secondary btn-block" @click="saveinfo">შენახვა</button>

         </form>







  </div>



</template>
<script>
import Multiselect from "@vueform/multiselect";

export default {
  components: {Multiselect},
  data(){
    return{
      formData: new FormData(),
      form:{
        fname: null,
        lname:null,
        phone:null,
        adress:null,
        email:null,
        piradoba:null,
        piradobisS:null,
        dabadebistarigi:null,
        userlebelID:0,
        gender:1,
        profesiaID:0,
        contract_typeID:0,
        statusID:0,
        contract_number:null,
        contract_date:null,
        shewyvetis_tar:null,
        putdate:null,





      },
      userlevels:null,
      profesia:null,
      isFiled:false,
      contract_type:null,
      status:null,
      contract_start_date:null,
      contract_end_date:null,



    }


  },mounted() {
    this.$store.dispatch('getusers').then(response=>{

      this.userlevels=response.data
    })
    this.$store.dispatch('getcontract_type').then(response=>{

      this.contract_type=response.data
    })
    this.$store.dispatch('getstatus').then(response=>{

      this.status=response.data
    })
    this.$store.dispatch('getprof').then(response=>{
      this.profesia=response.data

    })
  },methods:{
    getimg(event) {
      this.formData.append('img',event.target.files[0])
    },
    saveinfo(){

      this.formData.append('gender', this.form.gender)



      for (const key in this.form) {
        if(key!=='gender') {
          document.getElementById(key).style.border='1px solid black'

          if (this.form[key] == null || this.form[key] == 0) {

            document.getElementById(key).style.border = '1px solid red'

          } else {

            this.formData.append(key, this.form[key])
            this.isFiled = true
          }
        }


      }


      if(this.isFiled) {
        this.formData.append('branchID', this.$store.state.user.branchID)


        this.$store.dispatch('saveinfo', this.formData).then(response => {


          if(response.data.status=='OK'){
            this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით დაემატა')
          }else if(response.data.status=='photo.ext.error'){

            this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სურათი უნდა იყოს .jpg ფორმატის')
          }else if(response.data.status=='photo.empty.error'){

            this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, გთხოვთ აირჩიოთ სურათი')

          }


        })

      }




    }




  }


}
</script>