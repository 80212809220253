import { createRouter, createWebHashHistory } from 'vue-router'

import Home from '../views/Home.vue' 
import login from '../views/login.vue'
import CoreComponent from '../components/CoreComponent.vue'
import profile from '../views/profile.vue'
import logout from '../views/logout.vue'
import changesemester from '../views/changesemester.vue'
import addnewclass from '../views/addnewclass.vue'
import subjectmanagement from '../views/subjectmanagement.vue'
import pupilsRegistration from '../views/pupilsRegistration'
import listbyclass from '../views/listbyclass'
import expiredcontracts from '../views/expiredcontracts'
import accruals from '../views/accruals'
import addnewpersons from '../views/addnewperson'
import positionmanagement from '../views/positionmanagement'
import currentpersons from '../views/currentpersons'
import nocurrentpersons from '../views/nocurrentpersons'
import booklist from '../views/booklist'
import profileperson from '../views/profileperson'
import profilestudents from '../views/profilestudents'
import Summarycontrol from '../views/summarycontrol'

import Homework from '../views/homework'
import Evaluations from '../views/evaluations'
import Misses from '../views/misses'
import Summaries from '../views/summaries'
import Personalschedule from '../views/personalschedule'
import Tests from '../views/tests'
import AssessmentBysubject from '../views/AssessmentBysubject'
import teilnahme from '../views/teilnahme'
import klasssi from '../views/klasssi'
import Score from '../views/Score'
import Createticket from '../views/Createticket'
import watchpoint from '../views/watchpoint'
import * as path from "path";
import messages from '../views/messages'
import logistics from '../views/logistics'
import logisticsrequest from '../views/logisticsrequest'
import Logisticsapproval from '../views/Logisticsapproval'
import EventCalendar from '../views/EventCalendar'
import canteen from '../views/canteen'
import longlesson from '../views/longlesson'
import Psychologist from '../views/Psychologist'
import specmaswavleblebismimagreba from '../views/specmaswavleblebismimagreba'
import Cambridge from '../views/Cambridge'
import transport from '../views/transport'
import classes from '../views/classes'
import clubs from '../views/clubs'
import employees from '../views/employees'
const routes = [
 
    {
        path:'/',
        name:'',
        component:CoreComponent,
        children:[


                        {
                          path: '/',
                          name: 'მთავარი',
                          component: Home,
                          meta:{
                            requiresAuth:true 
                         }  
                        
                        } ,

                        {
                          path: '/changesemester',
                          name: 'სემესტრის გადართვა',
                          component:changesemester ,
                          meta:{
                            requiresAuth:true 
                         }  
                        
                        },

                        {
                          path: '/profile',
                          name: 'პროფილი',
                          component:profile,
                          meta:{
                            requiresAuth:true 
                         }  
                        
                        } ,
                        {
                           path:'/logout',
                           name:'გასვლა',
                           component:logout,
                           meta:{
                              requiresAuth:true 
                           }  


                        },

                         {
                           path:'/addnewclass',
                           name:'ახალი კლასის დამატება',
                           component:addnewclass,
                           meta:{
                              requiresAuth:true 
                           }  


                        },
                        {
                        path:'/subjectmanagement',
                        name:'საგნების მართვა',
                        component:subjectmanagement,
                        meta:{
                            requiresAuth:true

                        }

                        },
                        {
                            path:'/addpupls',
                            name:'მოსწავლეების დამატება',
                            component: pupilsRegistration,
                            meta:{
                                requiresAuth: true
                            }

                        },

                     {
                        path:'/listbyclass',
                        name:'სიიები კლასების მიხედვით',
                        component: listbyclass,
                        meta:{
                            requiresAuth: true,
                        }

            },
            {
                path:'/expiredcontracts',
                name:'ვადაგასული კონტრაქტები',
                component: expiredcontracts,
                meta:{
                    requiresAuth:true,
                }

            },
            {
                path: '/accruals',
                name:'მოსწავლეების დარიცხვები',
                component: accruals,
                meta:{
                    requiresAuth: true,
                }


            },
            {
                path:'/addnewperson',
                name:'თანამშრომლის დამატება',
                component: addnewpersons,
                meta:{
                    requiresAuth: true,

                }

            },
            {
                path: '/positionmanagement',
                name:'positionmanagement',
                component: positionmanagement,
                meta:{
                    requiresAuth: true,
                }

            },
            {
                path: '/employees',
                name:'employees',
                component: employees,
                meta:{
                    requiresAuth: true,
                }

            },

            {

                path: '/currentpersons',
                name: 'currentpersons',
                component: currentpersons,
                meta: {
                    requiresAuth: true,
                }
            },
            {

                path: '/specmaswavleblebismimagreba',
                name: 'specmaswavleblebismimagreba',
                component: specmaswavleblebismimagreba,
                meta: {
                    requiresAuth: true,
                }
            },
            {

                path: '/clubs',
                name: 'კლუბი',
                component: clubs,
                meta: {
                    requiresAuth: true,
                }
            },

            {

                path: '/transport',
                name: 'transport',
                component: transport,
                meta: {
                    requiresAuth: true,
                }
            },

            {
                path: '/nocurrentpersons',
                name: 'nocurrentpersons',
                component: nocurrentpersons,
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: '/booklist',
                name: 'booklist',
                component: booklist,
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: '/profileperson/:ID',
                name:'profileperson',
                component: profileperson,
                meta: {
                    requiresAuth: true,
                }
            },
            {
                    path: '/profilestudents/:ID',
                    name: 'profilestudents',
                    component: profilestudents,
                    meta: {
                        requiresAuth: true,
                }
            },
            {
                path: '/classes',
                name: 'classes',
                component: classes,
                meta: {
                    requiresAuth: true,
                }
            },

            {
                path:'/summarycontrol',
                name:'შემაჯამებლის კონტროლი',
                component:Summarycontrol,
                meta:{
                    requiresAuth: true,
                }

            },

            {
                path: '/homework',
                name: 'საშინაო დავალება',
                component: Homework,
                meta: {
                    requiresAuth: true,
                }

            },
            {
                path:'/evaluations',
                name:'შეფასება',
                component:Evaluations,
                meta:{
                    requiresAuth:true
                }


            },
            {
                path:'/misses',
                name:'გაცდენები',
                component:Misses,
                meta:{
                    requiresAuth:true
                }


            },
            {
                path:'/summaries',
                name:'შემაჯამებელი',
                component: Summaries,
                meta:{
                    requiresAuth:true
                }


            },
            {
                path:'/canteen',
                name:'სასადილო',
                component: canteen,
                meta:{
                    requiresAuth:true
                }


            },
            {
                path:'/longlesson',
                name:'გახანგძლივებული',
                component: longlesson,
                meta:{
                    requiresAuth:true
                }


            },
            {
                path: '/Psychologist',
                name: 'ფსიქოლოგი',
                component: Psychologist,
                meta:{
                    requiresAuth:true
                }


            },
            {
                path: '/personalschedule',
                name: 'პირადი ცხრილი',
                component:Personalschedule,
                meta: {
                    requiresAuth:true


                }
            },
            {
                path: '/tests',
                name:'ტესტები',
                component: Tests,
                meta: {
                    requiresAuth:true
                }


            },
            {
                path: '/logistics',
                name: 'ლოჯისტიკა',
                component: logistics,
                meta: {
                    requiresAuth: true


                }
            },
            {
                path: '/logisticsrequest',
                name: 'ლოჯისტიკის მოთხოვნები',
                component: logisticsrequest,
                meta: {
                    requiresAuth: true


                }
            },
            {
                path: '/Logisticsapproval',
                name: 'ლოჯისტიკის თანხმობა',
                component: Logisticsapproval,
                meta: {
                    requiresAuth: true


                }


            },
            {
                path: '/Cambridge',
                name: 'კემბრიჯი',
                component: Cambridge,
                meta: {
                    requiresAuth: true


                }


            },

            {
                path: '/EventCalendar',
                name: 'ღონისძიების კალენდარი',
                component: EventCalendar,
                meta: {
                    requiresAuth: true


                }


            },


            {

                path: '/AssessmentBysubject/:klasiID/:paramID/:klasi/:semestriID',
                name:'შეფასება საგნების მისედვით',
                component: AssessmentBysubject,
                meta: {
                    requiresAuth:true
                }

            },
            {
            path:'/teilnahme/:klasiID/:paramID/:klasi/:semestriID',
            name:'დასწრება',
            component:teilnahme,
            meta:{
             requiresAuth:true

              }

           },
            {
                path:'/klasssi/:klasiID/:saganiID',
                name:'klasssi',
                component:klasssi,
                meta:{
                    requiresAuth:true

                }

            },
            {

                path: '/Score/:themeID',
                name: 'Score',
                component: Score,
                meta: {
                    requiresAuth: true
                }
            },
            {
              path:'Createticket/:klasiID/:saganiID',
              name: 'Createticket',
              component: Createticket,
              meta:{
                  requiresAuth: true
              }

            },{
            path: 'watchpoint/:klasiID/:saganiID',
                name: 'watchpoint',
                component: watchpoint,
                meta: {
                requiresAuth: true
                }
            },{
            path: 'messages',
                name: 'messages',
                component: messages,
                meta: {
                requiresAuth: true
                }
            },




        ] 
    },
    {
                      
      path:'/login',
      name:'ავტორიზაცია',
      component:login
  
  
    },

  
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


router.beforeEach((to,from,next)=>{

      if(to.matched.some(record => record.meta.requiresAuth)){

          if(localStorage.getItem('token')){

              next()

          }else {

              next('/login')

          }


      } else {

            next()

      }    



})

 



export default router
