<template>
  <div class="container">
    <div class="row">

        <div class="col-4 mt-5">
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="2" v-model="form.textarea"></textarea>
        </div>
        <div class="col-4 text-center" style="margin-top:69px">

          <input class="form-check-input" type="checkbox" value="0" v-model="form.check">
          <label class="form-check-label ml-2" >
            საპატიო
          </label>
        </div>
      <div class="col-4">
        <button type="button" class="btn btn-secondary" @click="savemisskatedra()" style="margin-top:55px">შენახვა</button>
      </div>
      </div>

    <div class="row">
      <div class="col-6">
        <p class=" font-weight-bold mt-5">კათედრა</p>
        <div v-for="(katerdebi,i) in katedra " :key="i" class="my-3" style="cursor: pointer" @click="selectkatedrapersonal(katerdebi.katedraID)">
          {{katerdebi.katedra}}
        </div>
      </div>
      <div class="col-6" v-show="hidden">
        <p class=" font-weight-bold mt-5">სახელი  გვარი</p>
        <div v-for="(prs,i) in personal " :key="i" class="my-3">
          {{prs.saxeli}} {{prs.gvari}}
        </div>
      </div>
    </div>
    <div class="row my-5">
      <div class="col-12">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">სახელი</th>
            <th scope="col">გვარი</th>
            <th scope="col">სტატუსი</th>
            <th scope="col">გაცდენების რაოდენობა</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(gacden,i) in gacdena " :key="i" style="cursor: pointer" @click="getreason(gacden.personaliID)" data-toggle="modal" data-target=".chu">
            <td >{{gacden.saxeli}}</td>
            <td>{{gacden.gvari}}</td>
            <td>{{gacden.statusi}}</td>
            <td>{{gacden.raodenoba}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>


    <div class="modal fade bd-example-modal-lg chu" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="margin-top: 400px; background-color: white; color: black;width: 400px; height: 200px;margin-left: 800px">
      <div class="modal-dialog modal-lg">
        <div class="modal-content text-center" style="border: none">
<div v-for="(miss,i) in reason" :key="i">
  {{miss.reason}}
</div>
      </div>





    </div>
  </div>

  </div>



</template>
<script>

export default {
  data(){

    return{
      formData: new FormData(),
      form:{
        check:0,
        textarea:null,
        personID:null
      },
      katedra:null,
      personal:null,
      hidden:false,
      gacdena:null,
      reason:null


    }

  },mounted() {
    this.$store.dispatch('selectkaterdra').then(response=>{

      this.katedra=response.data


    })
    this.$store.dispatch('selectgacdenebi').then(response=>{

      this.gacdena=response.data
      console.log(response.data)


    })


  },methods:{
    selectkatedrapersonal(katedraID){

      this.$store.dispatch('selectkaterdrapers',{katedraID:katedraID}).then(response=>{

        this.personal=response.data
        this.hidden=true

      })
    },
    savemisskatedra(){
      this.formData.append('personID', this.$store.state.user.personaliID)
      this.formData.append('textarea', this.form.textarea)
      this.formData.append('check', this.form.check)
      this.$store.dispatch('selectmisskatedra',this.formData).then(response=>{

        if(response.data.status=='OK'){
          this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით  შეინახა')

        }else {
          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სცადეთ თავიდან!')

        }


      })
    },
    getreason(personID){
      this.$store.dispatch('selectgetreason',{personID:personID}).then(response=>{
        this.reason=response.data
        console.log(response.data)

      })
    }
  }
}
</script>