<template>

  <div class="container">

<!--    <div class="row"  v-for="(logistic,i) in request " :key="i">-->
<!--      <div class="col-4 mt-2">-->
<!--        {{logistic.saxeli}} {{logistic.gvari}}-->
<!--      </div>-->
<!--      <div class="col-4 mt-2">-->
<!--        {{logistic.Request}}-->
<!--      </div>-->
<!--      <div class="col-4">-->
<!--        <input type="number" class="form-control mt-2" id="number">-->
<!--      </div>-->


<!--      </div>-->

    <table class="table">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">სახელი</th>
        <th scope="col">გვარი</th>
        <th scope="col">მოთხოვნა</th>
        <th scope="col">ფასი</th>
        <th scope="col">დამტკიცებული/უარყოფილი</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(logistic,i) in request " :key="i">
        <th scope="row">{{logistic.LogisticsRequestID}}</th>
        <td>{{logistic.saxeli}}</td>
        <td>{{logistic.gvari}}</td>
        <td style="text-wrap:none">{{logistic.Request}}</td>
        <td ><input type="number" class="form-control" id="number" v-model="number[logistic.LogisticsRequestID]"> </td>
        <td>
          <i v-if="logistic.Approve==1" class='bx bx-check' style="color: #1c7430;font-size: 30px"></i>
          <i v-else="logistic.Approve==0" class='bx bx-x' style="color: red;font-size: 30px"></i>
        </td>

      </tr>
      <button v-if="request?.length>0" class="btn btn-secondary  p-2 mt-4" style="position: relative;left: 44%;bottom: 20px" @click="updatePrice()">შენახვა</button>
      </tbody>
    </table>


    </div>









</template>

<script>
export default {
  data(){
    return{

      request:null,
      number:{},
      appruve:null


    }
  },mounted() {
    this.$store.dispatch('getrequestlogistics').then(response=>{

        if(response.data) {

          this.request = response.data
          console.log(response.data)

          response.data.map((obj)=>{

            this.number[obj.LogisticsRequestID]=obj.Price

          })

        }

    })



  },methods:{

    updatePrice(){

      this.$store.dispatch('updatePrice',{price:this.number}).then(response=>{


        if (response.data.status == 'OK') {
          this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით შეინახა')
        } else if (response.data.status == 'ERROR') {
          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა')
        }


      })

    }


  }
}
</script>