<template>
  <div class="container-fluid">

      <div class="row">

            <button type="button" class="btn btn-danger mt-4 ml-3" data-toggle="modal" data-target=".bd-example-modal-lg" @click="clearForm"> წიგნების შეტანა</button>

            <div class="modal fade bd-example-modal-lg" tabindex="-1" id="bookForm" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">

                <form>
                  <div class="row mt-2 ml-1">
                    <div class="col-6">
                  <div class="form-group">
                    <input type="text" class="form-control"  placeholder="დასახელება" v-model="form.dasaxeleba" id="dasaxeleba">

                  </div>
                    </div>
                    <div class="col-6 ">
                      <div class="form-group mr-1">
                        <select class="form-control"  v-model="form.klasi" id="klasi">
                          <option value="0">აირჩიეთ კლასი</option>

                            <option v-for="(number,i) in numbers" :key="i" :value="number">{{number}} კლასი</option>

                        </select>

                      </div>
                    </div>
                  </div>
                  <div class="row  ml-1">
                    <div class="col-6">
                      <div class="form-group">
                        <input type="text" class="form-control"   placeholder="ავტორი" v-model="form.avtori" id="avtori">

                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mr-1">
                        <input type="text" class="form-control"  placeholder="ISBN" v-model="form.isbn" id="isbn">

                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mr-1">
                        <input type="text" class="form-control"  placeholder="UCD" v-model="form.UDC" id="UDC">

                      </div>
                    </div>
                  </div>
                  <div class="row ml-1">
                    <div class="col-6">
                      <div class="form-group">
                        <input type="text" class="form-control"   placeholder="გამოცემის წელი" v-model="form.gamocemisweli" id="gamocemisweli">

                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mr-1">
                        <input type="text" class="form-control"  placeholder="თარო" v-model="form.taro" id="taro">

                      </div>
                    </div>
                  </div>
                  <div class="row  ml-1">
                    <div class="col-6">
                      <div class="form-group">
                        <input type="text" class="form-control"  placeholder="გამომცემლობა" v-model="form.gamomcemloba" id="gamomcemloba">

                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mr-1">
                        <input type="number" class="form-control"  placeholder="რეგისტრაციის ნომები" v-model="form.registraciisnomeri" id="registraciisnomeri">

                      </div>
                    </div>
                  </div>
                  <div class="row  ml-1">
                    <div class="col-6">
                      <div class="form-group">
                        <input type="text" class="form-control"   placeholder="წყარო" v-model="form.wyaro" id="wyaro">

                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mr-1">
                        <input type="text" class="form-control" placeholder="ქსეროქსი" v-model="form.qseroqsi" id="qseroqsi">

                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mr-1">
                        <input type="date" class="form-control" placeholder="ყიდვის თარიღი" v-model="form.buydate" id="buydate">

                      </div>
                    </div>

                  </div>

                  <div class="row  ml-1">
                    <div class="col-6">
                      <div class="form-group">
                        <input type="number" class="form-control" placeholder="გვერდების რაოდენობა" v-model="form.gverdisraodenoba" id="gverdisraodenoba">

                      </div>
                    </div>
                  </div>
                  <div class="row ml-1 ">
                    <div class="col-6">
                      <div class="form-group ">
                        <select class="form-control"  v-model="form.enisarcheva" id="enisarcheva">
                          <option value="0">აირჩიე ენა</option>
                          <option v-for="(lang,i) in language" :key="i" :value="lang.ID">{{lang.Ena}}</option>
                        </select>

                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mr-1">
                        <select class="form-control" v-model="form.categoria" id="categoria">
                          <option value="0">აირჩიე კატეგორია</option>
                          <option v-for="(catrgoryy,i) in category" :key="i" :value="catrgoryy.ID">{{catrgoryy.ApplFieldName}}</option>

                        </select>

                      </div>
                    </div>
                  </div>

        <div class="col-12 mb-2 text-center">

          <button v-if="!updateinfo" type="button" class="btn btn-danger btn-block" @click="savefform">შენახვა</button>
          <button v-if="updateinfo" type="button" class="btn btn-danger btn-block" @click="updateForm">რედაქტირება</button>

        </div>

                </form>



            </div>
          </div>

          </div>


        <button type="button" class="btn btn-danger mt-4 ml-3" data-toggle="modal" data-target=".chu">აღრიცხვა</button>
        <form class="form-inline" style="position: relative;left: 800px;top:16px;">
          <input class="form-control " type="search" placeholder="ძიება" aria-label="Search" v-model="keyword">
<!--          <button class="btn btn-outline-success my-2 my-sm-0" type="submit">ძიება</button>-->
        </form>
        <div class="modal fade bd-example-modal-lg chu" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">

              <form>
                <div class="row mt-2 ml-1">
                  <div class="col-6">
                    <div class="form-group">
                      <label >პერსონალის არჩევა</label>
                      <Multiselect
                          v-model="bookinfo.selectperson"
                          :options="persons"
                          :searchable="true"
                      />



                    </div>
                  </div>
                  <div class="col-6 ">
                    <div class="form-group mr-1">
                      <label >წიგნის არჩევა</label>
                      <Multiselect
                          v-model="bookinfo.selectbooks"
                          :options="bookss"
                          :searchable="true"
                      />


                    </div>
                  </div>
                </div>
                <div class="row  ml-1">
                  <div class="col-6">
                    <div class="form-group">
                      <label >გატანის თარიღი</label>
                      <input type="date" class="form-control" v-model="bookinfo.out_date" id="out_date">

                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group mr-1">
                      <div class="form-group">
                        <label >შემოტანის თარიღი</label>
                        <input type="date" class="form-control" v-model="bookinfo.get_date" id="get_date">

                      </div>

                    </div>
                  </div>
                </div>
                <div class="col-4 mb-2" style="margin-left: 300px">
                  <input class="form-control" type="search" placeholder="ძიება" aria-label="Search" v-model="getout">
                </div>
                <div class="col-12 mb-2 text-center">

                  <button type="button" class="btn btn-danger btn-block" @click="savebookinfo">შენახვა</button>
                </div>

              </form>

              <div class="col-12 mt-3">
                <table class="table table-striped table-bordered table-responsive-lg ">
                  <thead>
                  <tr>
                    <th scope="col">პიროვნება</th>
                    <th scope="col">წიგნი</th>
                    <th scope="col">გატანის თარიღი</th>
                    <th scope="col">შემოტანის თარიღი</th>

                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(gettable,i) in getout?chooseperson.filter((obj)=>{

                return obj.Title?.includes(getout) || obj.out_date?.includes(getout)
                  || obj.bring_date?.includes(getout)  || obj.saxeli?.includes(getout)
                   || obj.gvari?.includes(getout)

            }):chooseperson " :key="i">
                    <th scope="row">{{gettable.saxeli}} {{gettable.gvari}}</th>
                    <td>{{gettable.Title}}</td>
                    <td>{{gettable.out_date}}</td>
                    <td>{{gettable.bring_date}}</td>

                    <td><button class="btn btn btn-outline-secondary" @click="Bringing(gettable.ID)" >შემოტანა</button></td>
                  </tr>


                  </tbody>
                </table>

              </div>


            </div>
          </div>





        </div>












      </div>




    <div class="row">
        <div class="col-12 mt-3">
          <table class="table table-striped table-bordered table-responsive">
            <thead>
            <tr>

              <th scope="col">დასახელება</th>
              <th scope="col">ავტორი</th>
              <th scope="col">გვერდების რაოდენობა</th>
              <th scope="col">წყარო</th>
              <th scope="col">თარო</th>
              <th scope="col">რეგისტრაციის ნომერი</th>
              <th scope="col">UDC</th>
              <th scope="col">ISBN</th>
              <th scope="col">გამოცემის წელი</th>
              <th scope="col">გამომცემლობა</th>
              <th scope="col">ყიდვის თარიღი</th>
              <th scope="col">ენა</th>
              <th scope="col">კლასი</th>
              <th scope="col">წიგნის ტიპი</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(getbook,i) in keyword?booklist.filter((obj)=>{

                return obj.Title?.includes(keyword) || obj.Authors?.includes(keyword)
                  || obj.PageNum?.includes(keyword) || obj.Source?.includes(keyword)
                  || obj.shelfNo?.includes(keyword) || obj.RegNo?.includes(keyword)
                  || obj.UDC?.includes(keyword)     || obj.ISBN?.includes(keyword)
                  || obj.PublDate?.includes(keyword)|| obj.PublHouse?.includes(keyword)
                 || obj.buydate?.includes(keyword)   || obj.Ena?.includes(keyword)
                  || obj.klasi?.includes(keyword)      || obj.ApplFieldName?.includes(keyword)

            }):booklist " :key="i">
              <th scope="row">{{getbook.Title}}</th>
              <td>{{getbook.Authors}}</td>
              <td>{{getbook.PageNum}}</td>
              <td>{{getbook.Source}}</td>
              <td>{{getbook.shelfNo}}</td>
              <td>{{getbook.RegNo}}</td>
              <td>{{getbook.UDC}}</td>
              <td>{{getbook.ISBN}}</td>
              <td>{{getbook.PublDate}}</td>
              <td>{{getbook.PublHouse}}</td>
              <td>{{getbook.buydate}}</td>
              <td>{{getbook.Ena}}</td>
              <td>{{getbook.klasi}}</td>
              <td>{{getbook.ApplFieldName}}</td>
              <td><button class="btn btn btn-outline-secondary" @click="deletbookinfo(getbook.ID)">წაშლა</button></td>
              <td>

                <button type="button" class="btn btn-outline-secondary  " data-toggle="modal" data-target=".bd-example-modal-lg" @click="updateinfoo(getbook.ID)"> რედაქტირება</button>





              </td>
            </tr>
            </tbody>
          </table>

        </div>



    </div>

  </div>


</template>

<script>

import Multiselect from "@vueform/multiselect";


export default {
  components: {Multiselect},
  data(){

    return{
      formData: new FormData(),
      form: {
        dasaxeleba:null,
        klasi: 0,
        avtori:null,
        isbn:null,
        UDC:null,
        gamocemisweli:null,
        taro:null,
        gamomcemloba:null,
        registraciisnomeri:null,
        wyaro:null,
        qseroqsi:null,
        gverdisraodenoba:null,
        enisarcheva:0,
        categoria:0,
        buydate:null
      },
      bookinfo:{
        selectperson:0,
        selectbooks:0,
        out_date:null,
        get_date:null,
      },
      booklist:null,
      isFiled:false,
      language:null,
      numbers:[1,2,3,4,5,6,7,8,9,10,11,12],
      category:null,
      chooseperson:null,
      persons:null,
      bookss:null,
      personID:null,
      keyword:null,
       updateinfo:false,
      updatableBookID:0,
      getout:null
    }
  },mounted() {






    this.$store.dispatch('getbooks',{branchID:this.$store.state.user.branchID}).then(response=>{

      this.booklist=response.data

    })
    this.$store.dispatch('chooseperson').then(response=>{
      this.chooseperson=response.data
    })

    this.$store.dispatch('persons', {branchID: this.$store.state.user.branchID}).then(response=>{

      this.persons=response.data



    })

    this.$store.dispatch('bookss', {branchID: this.$store.state.user.branchID}).then(response=>{

      this.bookss=response.data



    })



    this.$store.dispatch('getlanguage').then(response=>{

      this.language=response.data

    })
    this.$store.dispatch('getcategory').then(response=>{

      this.category=response.data

    })

  },methods:{



    Bringing(ID){
      this.$store.dispatch('Bringing',{ID:ID}).then(response => {


        if(response.data.status=='OK'){
          this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით განახლდა')
        }else if(response.data.status=='error'){

          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა')
        }




      })

    },
    deletbookinfo(ID){
if(confirm('ნამდვილად გსურთ წაშლა?')) {
  this.$store.dispatch('deletbookinfo', {ID: ID}).then(response => {

    if (response.data.status == 'OK') {
      this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით წაიშალა')
    } else if (response.data.status == 'photo.ext.error') {

      this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა')
    }

  })
}
    }
    ,

    updateinfoo(ID){
      this.$store.dispatch('updatebookinfoo', {ID:ID}).then(response => {

        this.updateinfo=true

        this.updatableBookID=ID

        this.form.dasaxeleba= response.data[0].Title
        this.form.klasi= response.data[0].klasi
        this.form.avtori= response.data[0].Authors
        this.form.isbn= response.data[0].ISBN
        this.form.gamocemisweli= response.data[0].PublDate
        this.form.taro= response.data[0].shelfno
        this.form.gamomcemloba= response.data[0].PublHouse
        this.form.registraciisnomeri= response.data[0].RegNo
        this.form.wyaro= response.data[0].Source
        this.form.qseroqsi= response.data[0].xerox
        this.form.gverdisraodenoba= response.data[0].PageNum
        this.form.categoria= response.data[0].ApplField
        this.form.enisarcheva=response.data[0].Language
        this.form.UDC=response.data[0].UDC
        this.form.buydate= response.data[0].buydate




      })

    },

    savebookinfo(){
      this.$store.dispatch('savebookinfo',this.bookinfo).then(response => {

        if(response.data.status=='OK'){
          this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით დაემატა')
        }else if(response.data.status=='photo.ext.error'){

          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სურათი უნდა იყოს .jpg ფორმატის')
        }else if(response.data.status=='photo.empty.error'){

          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, გთხოვთ აირჩიოთ სურათი')

        }


      })

    },

    savefform() {

      this.formData.append('branchID', this.$store.state.user.branchID)

      for (const key in this.form) {

        document.getElementById(key).style.border = '1px solid black'


        if (this.form[key] == null || this.form[key] == 0) {


          document.getElementById(key).style.border = '1px solid red'

        } else {

          this.formData.append(key, this.form[key])
          this.isFiled = true
        }

      }




      if (this.isFiled) {
        this.formData.append('branchID', this.$store.state.user.branchID)


        this.$store.dispatch('savefform', this.formData).then(response => {

          if (response.data.status == 'OK') {
            this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით დაემატა')
          } else if (response.data.status == 'error') {

            this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, გთხოვთ შეავსოთ ყველა ველი')

          }


        })

      }


    },

    clearForm(){

      console.log(this.form)



    },

    updateForm(){
      this.form.updatableBookID=this.updatableBookID
      this.$store.dispatch('updateFormbooks',this.form).then(response=>{
        if (response.data.status == 'OK') {
          this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით განახლდა')
        } else if (response.data.status == 'error') {

          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, გთხოვთ შეავსოთ ყველა ველი')

        }



      })



    }



  },

}

</script>

