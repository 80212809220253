<template>
  <div class="container-fluid">

       <div class="row main-wraper">
          <div class="col-12 col-sm-4 col-md-3 col-lg-2   sidebar " v-if="showSidebar">

                <div class="personinfo border-bottom">
                  

                      <div class="w-50  offset-3 rounded-circle mt-3">
                        
                          <img :src="image" class="img-fluid rounded-circle" />

                      </div>
               

                      <div class="text-center font-weight-bold"> {{$store.state.user.saxeli + ' '+$store.state.user.gvari}}  </div>
                      <div class="text-center">  {{$store.state.user.userlevels.userlevel}} </div>

                </div>
                <div class="menus">
                  <nav class="navbar">
                    <ul class="list-unstyled ml-1 pb-2 navbar-nav">
                   
                     

                      <li class="nav-item" v-for="(menu,index) in $store.state.user.userMenus" :key="index">
                        <router-link :to="menu.href"  class="nav-link text-dark" > <i :class='"bx bx-"+menu.icon'></i> {{menu.menuname}}</router-link>
                      </li>
                       



                    </ul>


                  </nav>
                </div>


          </div>


          <div :class="showSidebar ? 'col-12 col-sm-8 col-md-9 col-lg-10' : 'col'">

            <div class="row headBar">
                <div class="col-4 col-sm-2 col-md-1 text-center mt-1"><i class='bx bx-menu bx-lg' role="button" @click="toggleSidebar"></i> </div>
                <div class="d-none d-sm-block col-sm-6 col-md-9">
                  <div class="form-group mt-3">
<!--                  <input type="search" class="form-control mt-2" placeholder="ძიება...">-->
                    <Multiselect
                        placeholder="ძიება"
                        :filter-results="false"
                        :min-chars="3"
                        :resolve-on-load="false"
                        :delay="0"
                        :searchable="true"
                        @select="goToUserProfile"
                        :options="loadUsers" />
                  </div>
                  </div>
                <div class="col-4 col-sm-2 col-md-1 text-center mt-1"><router-link to="/messages"><i class='bx bx-envelope bx-lg message' role="button" ></i></router-link></div>
                <div class="col-4 col-sm-2 col-md-1 text-center dropdown mt-1">

                    <i class='bx bx-cog bx-lg' id="settingDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>


   
                
                    <div class="dropdown-menu dropDown-position" aria-labelledby="settingDropdown">
                          <router-link class="dropdown-item" to="/profile"> <i class='bx bx-user'></i> პროფილი</router-link> 
                          <div class="dropdown-divider"></div>
                          <router-link class="dropdown-item" to="/logout"><i class='bx bx-log-out-circle'></i> გამოსვლა</router-link>
                        </div>
                
                
                
                </div>


            </div>

             <div class="row">

                <div class="col">

                    <router-view/>

                </div>

            </div>




          </div>  
         
         </div> 
 

  </div>

      



</template>




<script>

import Multiselect from '@vueform/multiselect'
export default {
  components: { Multiselect },

  data() { 
    return {
      showSidebar:true ,
      width:document.documentElement.clientWidth ,
      userList: [],
      url_data:null,

    }  
  } ,

  computed: {
    image() {
      if(this.$store.state.user.personaliID){
      return require('@/assets/image/staff/'+this.$store.state.user.personaliID+'.jpg')
      }

    }
  },

  methods:{
    goToUserProfile(option) {
      const user = this.userList.find(o => parseInt(o.value) === parseInt(option))




      if (parseInt(user.moswavle) === 1) {

        this.$router.push({ path: '/profilestudents/'+user.value+'_'+user.moswavle })



      } else {
        this.$router.push({ path: '/profileperson/'+user.value+'_'+user.moswavle  })


      }
    },

    async loadUsers(query) {
      const response = await this.$store.dispatch('searchusers', { keyword: query })

      this.userList = await response.data

      return this.userList
    },

    toggleSidebar(){

      this.showSidebar = !this.showSidebar

    },
    getPersonDetail(){

      this.url_data = this.$route.params.ID;

      this.$store.dispatch('getpersonditeil', {personID:this.url_data}).then(response=>{

       this.$store.state.personditeil= response.data



      })

    },




  } ,
  created() {

    window.addEventListener('resize',()=>{

          this.width=document.documentElement.clientWidth 

          if(this.width<=555){

            this.showSidebar=false

          }else {

            this.showSidebar=true;

          }


    })

  },
  watch: {
    '$route'() {
      this.getPersonDetail()

    }
  },
}
</script>
